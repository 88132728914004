import React, { useEffect, useState } from "react";
import "../../assets/sass/_nutricheck.scss";
import Slim from "../../assets/images/SLIM_BODY 2.png";
import Average from "../../assets/images/SLIM_SHREDDED_BODY 2.png";
import SlimFemale from "../../assets/images/boost-performance.png";
import Enhance from "../../assets/images/enhance.png";
import { useTranslation } from "react-i18next";

const AdditionalGoalOptions = ({
  onAdditionalGoalOptionSelect,
  onNextSlide,
}) => {
  const { t } = useTranslation();
  const [selectedSpecificGoal, setSelectedSpecificGoal] = useState(null);

  const selectedGender = localStorage.getItem("gender");
  const images =
    selectedGender === "female"
      ? {
          slim: SlimFemale,
          average: Enhance,
        }
      : {
          slim: Slim,
          average: Average,
        };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData && userData["Select Your Specific Goal?"]) {
      setSelectedSpecificGoal(userData["Select Your Specific Goal?"]);
    }
  }, []);

  const handleSpecificGoalSelection = (goal) => {
    setSelectedSpecificGoal(goal);
    onAdditionalGoalOptionSelect(goal);
    onNextSlide();
  };

  return (
    <div className="additional-goal-options goal-selection">
      <h3>{t("specific_goal")}</h3>
      <div className="goal-options">
        <button
          className={`btn btn-default ${
            selectedSpecificGoal === "Strength Training" ? "selected" : ""
          }`}
          onClick={() => handleSpecificGoalSelection("Strength Training")}
        >
          <img src={images.slim} alt="slim-img" />
          <div>{t("slim_body")}</div>
        </button>
        <button
          className={`btn btn-default ${
            selectedSpecificGoal === "Endurance" ? "selected" : ""
          }`}
          onClick={() => handleSpecificGoalSelection("Endurance")}
        >
          <img src={images.average} alt="average-img" />
          <div>{t("slim_shredded_body")}</div>
        </button>
        <button
          className={`btn btn-default ${
            selectedSpecificGoal === "Mental Performance" ? "selected" : ""
          }`}
          onClick={() => handleSpecificGoalSelection("Mental Performance")}
        >
          <img src={images.average} alt="mental-img" />
          <div>{t("Mental_Performance")}</div>
        </button>
      </div>
    </div>
  );
};

export default AdditionalGoalOptions;
