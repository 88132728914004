import React, { useState, useEffect } from "react";
import "../../assets/sass/_nutricheck.scss";
import { Slider } from "antd";
import Add from "../../assets/images/plus.svg";
import Subtract from "../../assets/images/minus.svg";
import Arrow from "../../assets/images/arrow.svg";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

const HeightSelection = ({ onHeightSelect, onWeightSelect, onNextSlide }) => {
  const [height, setHeight] = useState(160); // Default height
  const [currentWeight, setCurrentWeight] = useState("");
  const [targetWeight, setTargetWeight] = useState("");
  const [unit, setUnit] = useState("kg");
  const { t } = useTranslation();

  // Load data from localStorage on component mount
  useEffect(() => {
    const savedHeight = localStorage.getItem("height");
    const savedWeightData = JSON.parse(localStorage.getItem("weightData"));

    if (savedHeight) setHeight(Number(savedHeight));
    if (savedWeightData) {
      setCurrentWeight(savedWeightData.currentWeight);
      setTargetWeight(savedWeightData.targetWeight);
      setUnit(savedWeightData.unit);
    }
  }, []);

  const handleHeightChange = (value) => {
    setHeight(value);
    localStorage.setItem("height", value); // Save height to localStorage
    onHeightSelect(value);
  };

  // Handle comma and dot as decimal separators
  const handleWeightChange = (setWeight, weightValue) => {
    // Replace comma with dot for consistency
    let value = weightValue.replace(",", ".");

    // Allow numbers with an optional decimal point and one digit after the decimal
    if (/^\d*(\.\d{0,1})?$/.test(value)) {
      setWeight(value);
    }
  };

  const handleCurrentWeightChange = (e) => {
    const value = e.target.value;
    handleWeightChange(setCurrentWeight, value);
  };

  const handleTargetWeightChange = (e) => {
    const value = e.target.value;
    handleWeightChange(setTargetWeight, value);
  };

  const handleUnitChange = (newUnit) => {
    if (newUnit !== unit) {
      const conversionFactor = newUnit === "kg" ? 0.453592 : 2.20462;
      const convertedCurrentWeight = (currentWeight * conversionFactor).toFixed(
        2
      );
      const convertedTargetWeight = (targetWeight * conversionFactor).toFixed(
        2
      );
      setCurrentWeight(convertedCurrentWeight);
      setTargetWeight(convertedTargetWeight);
      setUnit(newUnit);
    }
  };

  const handleNextClick = () => {
    if (currentWeight > 0 && targetWeight > 0) {
      const weightData = { currentWeight, targetWeight, unit };
      localStorage.setItem("weightData", JSON.stringify(weightData)); // Save weight data to localStorage
      onWeightSelect(weightData);
      onNextSlide();
    } else {
      Swal.fire({
        icon: "warning",
        title: t("valid_weights"),
        confirmButtonText: "OK",
        background: "#fff",
      });
    }
  };

  const incrementWeight = (setWeight, weight) => {
    let newWeight = parseFloat(weight || 0) + 0.1; // Increment by 0.1
    newWeight = newWeight.toFixed(1); // Limit to 1 decimal place
    setWeight(newWeight);
  };

  const decrementWeight = (setWeight, weight) => {
    let newWeight = parseFloat(weight || 0) - 0.1; // Decrement by 0.1
    if (newWeight >= 0) {
      newWeight = newWeight.toFixed(1); // Limit to 1 decimal place
      setWeight(newWeight);
    }
  };

  const formatter = (value) => `${value} cm`;

  return (
    <div className="height-weight-selection">
      <div className="age-selection">
        <h3>{t("select_height")}</h3>
        <p>{t("select_height_p")}</p>
        <Slider
          min={50}
          max={250}
          value={height}
          onChange={handleHeightChange}
          tipFormatter={formatter}
        />
      </div>
      <div className="weight-selection">
        <h3>{t("current_weight")}</h3>
        <div className="weight-inputs">
          <div className="input-header">
            <p>{t("select_unit")}</p>
            <div>
              <button
                className={`btn btn-default ${unit === "kg" ? "active" : ""}`}
                onClick={() => handleUnitChange("kg")}
              >
                Kg
              </button>
              <button
                className={`btn btn-default ${unit === "lb" ? "active" : ""}`}
                onClick={() => handleUnitChange("lb")}
              >
                lb
              </button>
            </div>
          </div>
          <div className="input-group">
            <label>
              {t("current_weight_lb")} ({unit}):
            </label>
            <div className="weight-input-wrapper">
              <input
                className="form-control"
                type="text"
                value={currentWeight}
                onChange={handleCurrentWeightChange}
                placeholder={t("Current_Weight")}
              />
              <div>
                <button
                  className="btn btn-default increment-decrement-button"
                  onClick={() =>
                    incrementWeight(setCurrentWeight, currentWeight)
                  }
                >
                  <img src={Add} alt="add" />
                </button>
                <button
                  className="btn btn-default increment-decrement-button"
                  onClick={() =>
                    decrementWeight(setCurrentWeight, currentWeight)
                  }
                >
                  <img src={Subtract} alt="subtract" />
                </button>
              </div>
            </div>
          </div>
          <div className="input-group">
            <label>
              {t("target_weight")} ({unit}):
            </label>
            <div className="weight-input-wrapper">
              <input
                className="form-control"
                type="text"
                value={targetWeight}
                onChange={handleTargetWeightChange}
                placeholder={t("Target_Weight")}
              />
              <div>
                <button
                  className="btn btn-default increment-decrement-button"
                  onClick={() => incrementWeight(setTargetWeight, targetWeight)}
                >
                  <img src={Add} alt="add" />
                </button>
                <button
                  className="btn btn-default increment-decrement-button"
                  onClick={() => decrementWeight(setTargetWeight, targetWeight)}
                >
                  <img src={Subtract} alt="subtract" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        onClick={handleNextClick}
        className="btn btn-default start-button"
        disabled={currentWeight <= 0 || targetWeight <= 0}
      >
        {t("next_btn")}
        <img className="img-fluid" src={Arrow} alt="arrow" />
      </button>
    </div>
  );
};

export default HeightSelection;
