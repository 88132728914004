// StarchIntakeSelection.js
import React, { useState, useEffect } from "react";
import { Slider } from "antd";
import Arrow from "../../assets/images/arrow.svg";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const StarchIntakeSelection = ({ onStarchIntakeSelect, onNextSlide }) => {
  const [starchIntake, setStarchIntake] = useState(0);
  const { t } = useTranslation();
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (
      userData &&
      userData[
        "How often per day do you consume starch sources such as potatoes, rice, bread, pasta, cereal flakes, etc.?"
      ]
    ) {
      setStarchIntake(
        userData[
          "How often per day do you consume starch sources such as potatoes, rice, bread, pasta, cereal flakes, etc.?"
        ]
      );
    }
  }, []);
  const handleStarchChange = (value) => {
    setStarchIntake(value);
    onStarchIntakeSelect(value);
  };

  const formatter = (value) => {
    const labels = ["0", "1", "2", "3", "4", "über 5"];
    return labels[value];
  };

  return (
    <div className="fitness-level-selection">
      <h3>{t("how_often_consume_starch")}</h3>
      <p>
        1 Portion = 1 Handvoll,
        <br /> was unter der Hand auf dem Teller gehäuft Platz hat
      </p>
      <div className="slider-container">
        <Slider
          min={0}
          max={5}
          value={starchIntake}
          onChange={handleStarchChange}
          tipFormatter={formatter}
          marks={{
            0: "0",
            1: "1",
            2: "2",
            3: "3",
            4: "4",
            5: "über 5",
          }}
        />
      </div>
      <button className="start-button btn btn-default" onClick={onNextSlide}>
        {t("next_btn")}
        <img className="img-fluid" src={Arrow} alt="arrow" />
      </button>
    </div>
  );
};

StarchIntakeSelection.propTypes = {
  onStarchSelect: PropTypes.func.isRequired,
  onNextSlide: PropTypes.func.isRequired,
};

export default StarchIntakeSelection;
