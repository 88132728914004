import React from "react";
import Erna from "../../assets/images/erna.jpg";
import Training from "../../assets/images/training.jpg";
import Coaching from "../../assets/images/onlinefoodcoaching-scaled-780x430-c-center.jpg";
import DarmaCoach from "../../assets/images/Darmcoaching-780x430-c-center.jpg";
import Betri from "../../assets/images/Plusfinance_410-780x430-c-center.jpg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Services = () => {
  const { t } = useTranslation();
  const services = [
    {
      icon: Erna,
      title: t("personalized"),
      link: "https://www.nutriteam.ch/angebote/ernaehrungsberatung/",
    },
    {
      icon: Training,
      title: t("guidance"),
      link: "https://www.nutriteam.ch/angebote/trainingsplanung/",
    },
    {
      icon: Coaching,
      title: t("workshops"),
      link: "https://www.nutriteam.ch/angebote/online-food-coaching/",
    },
    {
      icon: DarmaCoach,
      title: t("lifestyle"),
      link: "https://www.nutriteam.ch/angebote/darmcoaching/",
    },
    {
      icon: Betri,
      title: t("betri"),
      link: "https://www.nutriteam.ch/angebote/betriebliches-gesundheitsmanagement/",
    },
  ];
  return (
    <div className="service">
      <h3>{t("services")}</h3>
      <div className="services-row">
        {services.map((data, index) => (
          <div key={index} className="services-box">
            <img className="img-fluid" src={data.icon} alt="icon" />
            <h5>{data.title}</h5>
            <Link to={data.link} target="_blank">
              {t("view_details")}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
