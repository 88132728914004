import React, { useEffect, useState } from "react";
import "../../assets/sass/_nutricheck.scss";
import Slim from "../../assets/images/SLIM_BODY.png";
import Average from "../../assets/images/average.png";
import Heavy from "../../assets/images/BODYBUILDER 1.png";
import SlimFemale from "../../assets/images/boost-performance.png";
import AverageFemale from "../../assets/images/enhance.png";
// import HeavyFemale from "../../assets/images/heavy-female.png";
import { useTranslation } from "react-i18next";

const AdditionalGainMuscleOptions = ({
  onAdditionalGoalOptionSelect,
  onNextSlide,
}) => {
  const { t } = useTranslation();
  const [selectedMuscleGoal, setSelectedMuscleGoal] = useState(null);

  const selectedGender = localStorage.getItem("gender");
  const images =
    selectedGender === "female"
      ? {
          slim: SlimFemale,
          average: AverageFemale,
          heavy: AverageFemale,
        }
      : {
          slim: Slim,
          average: Average,
          heavy: Average,
        };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData && userData["Select Your Specific Goal?"]) {
      setSelectedMuscleGoal(userData["Select Your Specific Goal?"]);
    }
  }, []);

  const handleMuscleGoalSelection = (goal) => {
    setSelectedMuscleGoal(goal);
    onAdditionalGoalOptionSelect(goal);
    onNextSlide();
  };

  return (
    <div className="additional-goal-options goal-selection">
      <h3>{t("specific_goal")}</h3>
      <div className="goal-options">
        <button
          className={`btn btn-default ${
            selectedMuscleGoal === "Lose Weight" ? "selected" : ""
          }`}
          onClick={() => handleMuscleGoalSelection("Lose Weight")}
        >
          <img src={images.slim} alt="athlete-img" />
          <div>{t("athlete")}</div>
        </button>
        <button
          className={`btn btn-default ${
            selectedMuscleGoal === "Gain Weight" ? "selected" : ""
          }`}
          onClick={() => handleMuscleGoalSelection("Gain Weight")}
        >
          <img src={images.average} alt="hero-img" />
          <div>{t("hero")}</div>
        </button>
        <button
          className={`btn btn-default ${
            selectedMuscleGoal === "Maintain Weight" ? "selected" : ""
          }`}
          onClick={() => handleMuscleGoalSelection("Maintain Weight")}
        >
          <img src={images.heavy} alt="bodybuilder-img" />
          <div>{t("bodybuilder")}</div>
        </button>
      </div>
    </div>
  );
};

export default AdditionalGainMuscleOptions;
