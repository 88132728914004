import React, { useState, useEffect } from "react";
import { Slider } from "antd";
import "antd/dist/reset.css";
import FatImg1 from "../../assets/images/fat-img1.webp";
import FatImg2 from "../../assets/images/fat-img2.webp";
import FatImg3 from "../../assets/images/fat-img3.webp";
import FatImg4 from "../../assets/images/fat-img4.webp";
import FatImg5 from "../../assets/images/fat-img5.webp";
import FatImg6 from "../../assets/images/fat-img6.webp";
import FatImg7 from "../../assets/images/fat-img7.webp";
import FatImg8 from "../../assets/images/fat-img8.webp";
import FemaleSlim from "../../assets/images/female-slim2.png";
import FemaleSlim1 from "../../assets/images/female-slim.png";
import FemaleAverage from "../../assets/images/female-average3.png";
import FemaleAverage1 from "../../assets/images/female-average2.png";
import FemaleAverage2 from "../../assets/images/female-average.png";
import FemaleHeavy from "../../assets/images/female-heavy3.png";
import FemaleHeavy1 from "../../assets/images/female-heavy2.png";
import FemaleHeavy3 from "../../assets/images/female-heavy.png";
// import FemaleImg1 from "../../assets/images/female-slim2.png";
// import FemaleImg2 from "../../assets/images/female-img3.png";
// import FemaleImg3 from "../../assets/images/female-img7.png";
import { useTranslation } from "react-i18next";
import Arrow from "../../assets/images/arrow.svg";
import Swal from "sweetalert2";

const BodyFatLevelSelection = ({ onFatLevelSelect, onNextSlide }) => {
  const [selectedFatLevel, setSelectedFatLevel] = useState(null);
  const gender = localStorage.getItem("gender");
  const selectedGender = localStorage.getItem("gender");
  const { t } = useTranslation();
  const fatLevelImages =
    selectedGender === "female"
      ? [
          { range: [0, 5], image: FemaleSlim },
          { range: [6, 10], image: FemaleSlim1 },
          { range: [11, 15], image: FemaleAverage1 },
          { range: [16, 20], image: FemaleAverage1 },
          { range: [21, 25], image: FemaleAverage1 },
          { range: [26, 30], image: FemaleHeavy },
          { range: [31, 35], image: FemaleHeavy1 },
          { range: [36, 40], image: FemaleHeavy3 },
        ]
      : [
          { range: [0, 5], image: FatImg1 },
          { range: [6, 10], image: FatImg2 },
          { range: [11, 15], image: FatImg3 },
          { range: [16, 20], image: FatImg4 },
          { range: [21, 25], image: FatImg5 },
          { range: [26, 30], image: FatImg6 },
          { range: [31, 35], image: FatImg7 },
          { range: [36, 40], image: FatImg8 },
        ];

  const getImageForFatLevel = (fatLevel) => {
    for (const { range, image } of fatLevelImages) {
      if (fatLevel >= range[0] && fatLevel <= range[1]) {
        return image;
      }
    }
    return null;
  };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData && userData["Choose Your Body Fat Level"]) {
      setSelectedFatLevel(userData["Choose Your Body Fat Level"]);
    }
  }, []);

  const handleFatLevelChange = (value) => {
    setSelectedFatLevel(value);
  };

  const handleNextClick = () => {
    if (selectedFatLevel !== null) {
      onFatLevelSelect(selectedFatLevel);
      onNextSlide();
    } else {
      Swal.fire({
        icon: "warning",
        title: t("select_body_fat"),
        confirmButtonText: "OK",
        background: "#fff",
      });
    }
  };

  const currentImage = getImageForFatLevel(selectedFatLevel);

  return (
    <div className="body-fat-level-selection">
      <h3>{t("fat_level")}</h3>
      <div className="fat-box">
        <div>
          {currentImage ? (
            <img
              src={currentImage}
              alt={`Body fat level ${selectedFatLevel}`}
              className="fat-level-image"
            />
          ) : (
            <p>No image available for this fat level.</p>
          )}
        </div>
        <div>
          <Slider
            min={0}
            max={40}
            step={1}
            value={selectedFatLevel}
            onChange={handleFatLevelChange}
            marks={{
              0: "0%",
              5: "5%",
              10: "10%",
              15: "15%",
              20: "20%",
              25: "25%",
              30: "30%",
              35: "35%",
              40: "40%",
            }}
            tooltip={{ formatter: (value) => `${value}%` }}
          />
        </div>
      </div>
      {/* <p>Selected Body Fat Level: {selectedFatLevel}%</p> */}
      {/* {currentImage ? (
        <img
          src={currentImage}
          alt={`Body fat level ${selectedFatLevel}`}
          className="fat-level-image"
        />
      ) : (
        <p>No image available for this fat level.</p>
      )} */}
      <button
        className="start-button btn btn-default"
        disabled={selectedFatLevel === null}
        onClick={handleNextClick}
      >
        {t("next_btn")}
        <img className="img-fluid" src={Arrow} alt="arrow" />
      </button>

      {gender === "male" ? (
        <div className="table-responsive mt-5">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Alter</th>
                <th>- (Niedrig)</th>
                <th>0 (Normal)</th>
                <th>+ (Hoch)</th>
                <th>++ (Sehr hoch)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>6</td>
                <td>&lt; 11,8%</td>
                <td>11,8 - 21,7%</td>
                <td>21,8 - 23,7%</td>
                <td>&gt; 23,8%</td>
              </tr>
              <tr>
                <td>7</td>
                <td>&lt; 12,1%</td>
                <td>12,1 - 23,2%</td>
                <td>23,3 - 25,5%</td>
                <td>&gt; 25,6%</td>
              </tr>
              <tr>
                <td>8</td>
                <td>&lt; 12,4%</td>
                <td> 12,4 - 24,8%</td>
                <td> 24,9 - 27,7%</td>
                <td>&gt; 27,8%</td>
              </tr>
              <tr>
                <td>9</td>
                <td>&lt; 12,6%</td>
                <td> 12,6 - 26,5%</td>
                <td> 26,6 - 30,0%</td>
                <td>&gt; 30,1%</td>
              </tr>
              <tr>
                <td>10</td>
                <td>&lt; 12,8%</td>
                <td> 12,8 - 27,9%</td>
                <td> 28,0 - 31,8%</td>
                <td>&gt; 31,9%</td>
              </tr>
              <tr>
                <td>11</td>
                <td>&lt; 12,6%</td>
                <td> 12,6 - 28,5%</td>
                <td> 28,6 - 32,6%</td>
                <td>&gt; 32,7%</td>
              </tr>
              <tr>
                <td>12</td>
                <td>&lt; 12,3%</td>
                <td> 12,3 - 28,2%</td>
                <td> 38,3 - 32,4%</td>
                <td>&gt; 32,5%</td>
              </tr>
              <tr>
                <td>13</td>
                <td>&lt; 11,6%</td>
                <td> 11,6 - 27,5%</td>
                <td> 27,6 - 31,3%</td>
                <td>&gt; 31,4%</td>
              </tr>
              <tr>
                <td>14</td>
                <td>&lt; 11,1%</td>
                <td> 11,1 - 26,4%</td>
                <td> 26,5 - 30,0%</td>
                <td>&gt; 30,1%</td>
              </tr>

              <tr>
                <td>15</td>
                <td>&lt; 10,8%</td>
                <td> 10,8 - 25,4%</td>
                <td> 25,5 - 28,7%</td>
                <td>&gt; 28,8%</td>
              </tr>
              <tr>
                <td>16</td>
                <td>&lt; 10,4%</td>
                <td> 10,4 - 24,7%</td>
                <td> 24.8 - 27,7%</td>
                <td>&gt; 27,8%</td>
              </tr>
              <tr>
                <td>17</td>
                <td>&lt; 10,1%</td>
                <td> 10,1 - 24,2%</td>
                <td> 24,3 - 26,8%</td>
                <td>&gt; 26,9%</td>
              </tr>
              <tr>
                <td>18 - 39</td>
                <td>&lt; 8,0%</td>
                <td> 8,0 - 19,9%</td>
                <td> 20,0 - 24,9%</td>
                <td>&gt; 35,0%</td>
              </tr>
              <tr>
                <td>40 - 59</td>
                <td>&lt; 11,0%</td>
                <td> 11,0 - 21,9%</td>
                <td> 22,0 - 27,9%</td>
                <td>&gt; 38,0%</td>
              </tr>
              <tr>
                <td>60 - 80</td>
                <td>&lt; 13,0%</td>
                <td> 12,0 - 24,9%</td>
                <td> 25,0 - 29,9%</td>
                <td>&gt; 30,0%</td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <div className="table-responsive mt-5">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Alter</th>
                <th>- (Niedrig)</th>
                <th>0 (Normal)</th>
                <th>+ (Hoch)</th>
                <th>++ (Sehr hoch)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>6</td>
                <td>&lt; 13,8%</td>
                <td>13,8 - 24,9%</td>
                <td>25,0 - 27,0%</td>
                <td>&gt; 27,1%</td>
              </tr>
              <tr>
                <td>7</td>
                <td>&lt; 14,4%</td>
                <td>14,4 - 27,0%</td>
                <td>27,1 - 29,6%</td>
                <td>&gt; 29,7%</td>
              </tr>
              <tr>
                <td>8</td>
                <td>&lt; 15,1%</td>
                <td> 15,1 - 29,1%</td>
                <td> 29,2 - 31,8%</td>
                <td>&gt; 32,0%</td>
              </tr>
              <tr>
                <td>9</td>
                <td>&lt; 15,8%</td>
                <td> 15,8 - 30,8%</td>
                <td> 30,9 - 33,8%</td>
                <td>&gt; 33,9%</td>
              </tr>
              <tr>
                <td>10</td>
                <td>&lt; 16,1%</td>
                <td> 16,1 - 32,2%</td>
                <td> 32,3 - 35,2%</td>
                <td>&gt; 35,3%</td>
              </tr>
              <tr>
                <td>11</td>
                <td>&lt; 16,3%</td>
                <td> 16,3 - 33,1%</td>
                <td> 33,2 - 36,0%</td>
                <td>&gt; 36,1%</td>
              </tr>
              <tr>
                <td>12</td>
                <td>&lt; 16,4%</td>
                <td> 16,4 - 33,5%</td>
                <td> 33,6 - 36,3%</td>
                <td>&gt; 36,4%</td>
              </tr>
              <tr>
                <td>13</td>
                <td>&lt; 16,4%</td>
                <td> 16,4 - 33,8%</td>
                <td> 33,9 - 36,5%</td>
                <td>&gt; 36,6%</td>
              </tr>
              <tr>
                <td>14</td>
                <td>&lt; 16,3%</td>
                <td> 16,3 - 33,0%</td>
                <td> 34,1 - 36,7%</td>
                <td>&gt; 36,8%</td>
              </tr>

              <tr>
                <td>15</td>
                <td>&lt; 16,1%</td>
                <td> 16,1 - 34,2%</td>
                <td> 34,3 - 36,9%</td>
                <td>&gt; 37,0%</td>
              </tr>
              <tr>
                <td>16</td>
                <td>&lt; 15,8%</td>
                <td> 15,8 - 34,5%</td>
                <td> 34.6 - 37,1%</td>
                <td>&gt; 37,2%</td>
              </tr>
              <tr>
                <td>17</td>
                <td>&lt; 15,4%</td>
                <td> 15,4 - 34,7%</td>
                <td> 34,8 - 37,3%</td>
                <td>&gt; 37,4%</td>
              </tr>
              <tr>
                <td>18 - 39</td>
                <td>&lt; 21,0%</td>
                <td> 21,0 - 32,9%</td>
                <td> 33,0 - 38,9%</td>
                <td>&gt; 39,0%</td>
              </tr>
              <tr>
                <td>40 - 59</td>
                <td>&lt; 23,0%</td>
                <td> 23,0 - 33,9%</td>
                <td> 34,0 - 39,9%</td>
                <td>&gt; 40,0%</td>
              </tr>
              <tr>
                <td>60 - 80</td>
                <td>&lt; 24,0%</td>
                <td> 24,0 - 35,9%</td>
                <td> 36,0 - 41,9%</td>
                <td>&gt; 42,0%</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default BodyFatLevelSelection;
