import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Arrow from "../../assets/images/arrow.svg";
import "../../assets/sass/_nutricheck.scss";
import { useTranslation } from "react-i18next";

const NutriCheck = ({
  showIntro,
  currentSlide,
  slides,
  onStart,
  onPrevious,
  onNext,
}) => {
  const { t } = useTranslation();

  return (
    <div className="nutri">
      {showIntro ? (
        <div className="nutri-box">
          <h3>{t("ki")}</h3>
          <p>{t("support_text")}</p>
          <ol>
            <li>{t("weight_loss")}</li>
            <li>{t("digestive")}</li>
            <li>{t("performance")}</li>
            <li>{t("stabilize")}</li>
          </ol>
          <p>{t("evaluation")}</p>
          {/* <p>{t("carefully")}</p> */}
          <button onClick={onStart} className="start-button btn btn-default">
            {t("lets_get_started")}
            <img className="img-fluid" src={Arrow} alt="arrow" />
          </button>
        </div>
      ) : (
        <div className="slide-wrapper" style={{ position: "relative" }}>
          <div className="slide-container" style={{ overflow: "hidden" }}>
            <TransitionGroup>
              <CSSTransition
                key={currentSlide}
                timeout={300}
                classNames="slide"
              >
                <div className="slide">{slides[currentSlide]}</div>
              </CSSTransition>
            </TransitionGroup>
          </div>
        </div>
      )}
    </div>
  );
};

export default NutriCheck;
