import React, { useEffect, useState } from "react";
import "../../assets/sass/_nutricheck.scss";
import Slim from "../../assets/images/SLIM_SHREDDED_BODY.png";
import Average from "../../assets/images/HERO.png";
import Heavy from "../../assets/images/BEACH_BODY.png";
import SlimFemale from "../../assets/images/boost-performance.png";
import AverageFemale from "../../assets/images/weight-management.png";
import Enhance from "../../assets/images/enhance.png";
import { useTranslation } from "react-i18next";

const GoalOptions = ({ onGoalOptionSelect, onNextSlide }) => {
  const { t } = useTranslation();
  const [selectedGoal, setSelectedGoal] = useState(null);

  const selectedGender = localStorage.getItem("gender");
  const images =
    selectedGender === "female"
      ? {
          slim: SlimFemale,
          average: AverageFemale,
          heavy: Enhance,
        }
      : {
          slim: Slim,
          average: Average,
          heavy: Heavy,
        };

  useEffect(() => {
    // Retrieve userData from localStorage and set the selected goal
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData && userData["Select Your Goal?"]) {
      setSelectedGoal(userData["Select Your Goal?"]);
    }
  }, []);

  const handleGoalSelection = (goal) => {
    setSelectedGoal(goal); // Update the local state
    onGoalOptionSelect(goal); // Trigger the parent callback
    onNextSlide(); // Proceed to the next slide
  };

  return (
    <div className="goal-selection">
      <h3>{t("select_goal")}</h3>
      <div className="goal-options">
        <button
          className={`btn btn-default ${
            selectedGoal === "Boost Performance" ? "selected" : ""
          }`}
          onClick={() => handleGoalSelection("Boost Performance")}
        >
          <img src={images.slim} alt="slim-img" />
          <div>{t("lose_weight")}</div>
        </button>
        <button
          className={`btn btn-default ${
            selectedGoal === "Weight Management" ? "selected" : ""
          }`}
          onClick={() => handleGoalSelection("Weight Management")}
        >
          <img src={images.average} alt="average-img" />
          <div>{t("gain_muscle")}</div>
        </button>
        <button
          className={`btn btn-default ${
            selectedGoal === "Enhance Well-being" ? "selected" : ""
          }`}
          onClick={() => handleGoalSelection("Enhance Well-being")}
        >
          <img src={images.heavy} alt="heavy-img" />
          <div>{t("get_shredded")}</div>
        </button>
      </div>
    </div>
  );
};

export default GoalOptions;
