import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const EggsSelection = ({ onEggConsumptionSelect, onNextSlide }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionSelect = (option) => {
    setSelectedOption(option.text);
    onEggConsumptionSelect(option.text);
    onNextSlide();
  };

  const { t } = useTranslation();

  return (
    <div className="egg-consumption-selection">
      <h3>{t("eggs")}</h3>
      <div className="egg-consumption-buttons diet-options">
        {[
          {
            text: t("zero_eggs"),
            icon: "❌",
            // subtext: t("zero_eggs_sec"),
          },
          {
            text: t("one_to_two_eggs"),
            icon: "🥚🥚",
            // subtext: t("one_to_two_eggs_sec"),
          },
          {
            text: t("three_to_four_eggs"),
            icon: "🥚🥚🥚🥚",
            // subtext: t("three_to_four_eggs_sec"),
          },
          {
            text: t("five_or_more_eggs"),
            icon: "🥚🥚🥚🥚🥚",
            // subtext: t("five_or_more_eggs_sec"),
          },
        ].map(({ text, icon, subtext }) => (
          <button
            key={text}
            onClick={() => handleOptionSelect({ text })}
            className={
              selectedOption === text
                ? "selected btn btn-default"
                : "btn btn-default"
            }
          >
            <div>
              {text} <span className="icon">{icon}</span>
            </div>
            {subtext && <div className="subtext">{subtext}</div>}
          </button>
        ))}
      </div>
    </div>
  );
};

export default EggsSelection;
