import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const AlcoholTypeSelection = ({ onAlcoholTypeSelect, onNextSlide }) => {
  const { t } = useTranslation();
  const alcoholTypes = [
    t("Wein"),
    t("Beer"),
    t("Spirits"),
    t("Cocktails"),
    t("none"),
  ];
  const [selectedAlcoholTypes, setSelectedAlcoholTypes] = useState([]);

  const handleAlcoholToggle = (type) => {
    if (type === t("none")) {
      setSelectedAlcoholTypes((prev) => (prev.includes(type) ? [] : [type]));
    } else {
      setSelectedAlcoholTypes((prev) => {
        if (prev.includes(t("none"))) {
          return [type];
        } else if (prev.includes(type)) {
          return prev.filter((t) => t !== type);
        } else {
          return [...prev, type];
        }
      });
    }
  };
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData && userData["What kind of alcohol do you drink?"]) {
      setSelectedAlcoholTypes(userData["What kind of alcohol do you drink?"]);
    }
  }, []);
  const handleNextClick = () => {
    onAlcoholTypeSelect(selectedAlcoholTypes);
    onNextSlide();
  };
  const getIcon = (type) => {
    const icons = {
      [t("Wein")]: "🍷",
      [t("Beer")]: "🍺",
      [t("Spirits")]: "🥃",
      [t("Cocktails")]: "🍹",
      [t("none")]: "❌",
    };
    return icons[type] || "";
  };

  return (
    <div className="multiple-issues-selection">
      <h3>{t("alcohol_type")}</h3>
      <div className="issues-options">
        {alcoholTypes.map((type) => (
          <div key={type} className="issue-option">
            <label className="form-control">
              <input
                type="checkbox"
                name="checkbox-checked"
                checked={selectedAlcoholTypes.includes(type)}
                onChange={() => handleAlcoholToggle(type)}
              />
              {type} <span className="icon">{getIcon(type)}</span>
            </label>
          </div>
        ))}
      </div>
      <button
        onClick={handleNextClick}
        disabled={selectedAlcoholTypes.length === 0}
        className="btn btn-default start-button"
      >
        {t("next_btn")}
      </button>
    </div>
  );
};

export default AlcoholTypeSelection;
