import { initReactI18next } from "react-i18next";
import i18n from "i18next";

i18n.use(initReactI18next).init({
  lng: localStorage.getItem("lang") ? localStorage.getItem("lang") : "gm",

  resources: {
    en: {
      translation: {
        qualified_results: "Over 430 qualified results",
        Transform_Heading: "Transform your ❤️ health with us",
        Personalized: "Personalized nutrition coaching",
        Welcome:
          "Welcome to KI-Nutritem – your expert for personalized nutrition and health optimization.",
        Certified:
          "Our certified nutritionists provide you with customized plans and ongoing support on your weight loss journey. Using our advanced LLM model aligned with Mediterranean Diet, Hormone Balance, Microbiome Optimization and Fitness, we deliver precise, evidence-based recommendations. Our AI-powered application analyzes your individual needs and creates personalized nutritional strategies to take your health to a new level. Start your transformation today and experience the precision and efficiency of data-driven nutrition coaching.",
        lets_go: "Let's go",
        choose_individual: "Choose individual program",
        How_it_works: "How it works",
        get_started:
          "Get started easily and effectively on the path to better health and nutrition with our step-by-step instructions. We make it easy to get started and help you quickly navigate our platform and use the resources you need to achieve your health goals. This is how it works:",
        here_work: "Here's how it works",
        Features: "Our focus - your benefits",
        wellness:
          "Welcome to the features section of Nutritionist – your one-stop partner for personalized nutrition and wellness.",
        personalized: "Personalized nutrition plans",
        personalized_p:
          "Experience tailored nutritional strategies optimized by our advanced AI technology. Our application only analyzes anonymized data such as your health, activity level and dietary habits and does not collect any personal information. Get precise recommendations and adapt your nutrition plan individually. Through continuous updates and intelligent learning processes, your plan is regularly optimized so that you always make the best progress - in line with the highest data protection standards.",
        guidance: "Guidance from certified nutritionists",
        guidance_p:
          "Our team of experienced and certified nutritionists will provide you with professional advice and support throughout your entire journey. We'll answer your questions, address your concerns, and keep you motivated as you pursue your goals.",
        food: "Food tracking and analysis",
        food_p:
          "Easily track your food intake with our easy-to-use app. Our nutritionists analyze your diet and eating habits to gain insights, help you identify opportunities for improvement and provide personalized recommendations.",
        meal: "Meal planning and recipes",
        meal_p:
          "Access an extensive collection of delicious and healthy recipes tailored specifically to your dietary needs. Our advanced AI technology helps you do this by optimizing recipes and meal plans based on your preferences and goals. Additionally, our nutritionists create personalized meal plans complemented by AI-driven recommendations to help you stay on track and enjoy nutritious meals. This means you benefit from an intelligent combination of expert knowledge and state-of-the-art technology.",
        lifestyle: "Lifestyle and behavior coaching",
        lifestyle_p:
          "Achieving lasting results requires more than just a diet plan. Our nutritionists will work with you to develop healthy habits, address emotional eating, and provide strategies for overcoming obstacles along the way.",
        workshops: "Nutritional education and workshops",
        workshops_p:
          "Expand your nutrition knowledge with informative articles and interactive workshops delivered through our AI-powered app. The intelligent platform provides you with tailored learning content and resources based on your individual needs. Our app helps you make informed decisions and gives you the tools you need to achieve long-term success and healthy habits.",
        blogs_h: "Our blogs",
        blogs_p:
          "Our blog is a treasure trove of informative and engaging articles written by our team of nutritionists, dietitians and wellness experts. This is what you can expect from our blog.",
        read_less: "Read Less",
        read_more: "Read More",
        practice_h: "More from practice",
        practice_p: "Be inspired by the success stories of our users!",
        feedback_p:
          "Many have already shared their progress with our app. You too can share your experiences to motivate others and support the further development of our technology. Your feedback helps us continually improve our app and expand scientific knowledge.",
        choose_gender: "Choose Your Gender?",
        select_goal: "Select Your Goal",
        select_age: "Select Your Age Group",
        select_age_p:
          "Slide to Your Age - Because every age has its own wisdom",
        next_btn: "Next",
        select_height: "Select Your Height",
        select_height_p:
          "Slide to Your Height – Because every body size has its own balance",
        select_body_type: "Select Your Body Type",
        slim: "Slim",
        average: "Average",
        heavy: "Heavy",
        lose_weight: "Boost Performance",
        gain_muscle: "Weight Management",
        get_shredded: "Enhance Well-being",
        specific_goal: "Select Your Specific Goal",
        slim_body: "Strength Training",
        slim_shredded_body: "Endurance",
        Mental_Performance: "Mental Performance",
        athlete: "Lose Weight",
        hero: "Gain Weight",
        bodybuilder: "Maintain Weight",
        beach_body: "Boost Vitality",
        workout_body: "Eat Balanced Meals",
        cross_fit_body: "Increase Physical Activity",
        promote_recovery: "Promote Recovery",
        back: "Back",
        stick: "Do you stick to one of these diets?",
        vegetarian: "Vegetarian",
        vegan: "Vegan",
        keto: "Keto",
        mediterranean: "Mediterranean",
        none: "None of the above",
        sugary_food: "How often do you eat or drink sugary foods or drinks?",
        not_often: "Not often. I don't like sweets that much.",
        three_times: "3-5 times per week",
        every_day: "Almost every day",
        every_day_4: "Several times a day",
        water_intake: "How much water do you drink every day?",
        coffee: "Coffee or tea only",
        two_glasses: "Less than 2 glasses",
        six_glasses: "2-6 glasses",
        seven: "7-10 glasses",
        ten_glasses: "More than 10 glasses",
        medications:
          "Which of the following medications are you currently taking or have you taken in the last three months?",
        current_weight:
          "What is your current weight and how much would you like to weight?",
        select_unit: "Select unit",
        current_weight_lb: "Current weight",
        target_weight: "Target weight",
        fitness_level: "What is your fitness level?",
        fitness_level_p:
          "Choose on a scale from 1 to 10, where 1 stands for beginner and 10 for a very advanced fitness level. Use the slider to determine your current level.",
        fat_level: "Choose Your Body Fat Level",
        meal_plan: "Let Us Create a Meal Plan Based on Your Preferences",
        veggies: "Veggies",
        grains: "Grains",
        ingredients: "Ingredients",
        meat_fish: "Meat & Fish",
        fruits: "Fruits",
        allergic_h: "Select What Are You Allergic To",
        support_text: "Do you need support?",
        weight_loss: "Do you need support with a desired weight loss?",
        digestive: "For digestive problems and intolerances?",
        performance: "With optimizing your physical and mental performance?",
        stabilize: "With stabilizing your immune and hormone system?",
        evaluation:
          "You will receive your evaluation in 5-10 minutes. Read the following questions carefully and answer them as precisely as possible.",
        carefully:
          "Read the following questions carefully and answer them with a yes or no.",
        lets_get_started: "Let's get started",
        Broccoli: "Broccoli",
        Cauliflower: "Cauliflower",
        Onion: "Onion",
        Bell_Pepper: "Bell Pepper",
        Eggplant: "Eggplant",
        Cabbage: "Cabbage",
        Asparagus: "Asparagus",
        Spinach: "Spinach",
        Cucumber: "Cucumber",
        Tomato: "Tomato",
        Rice: "Rice",
        Quinoa: "Quinoa",
        Couscous: "Couscous",
        Buckwheat: "Buckwheat",
        Millet_Groats: "Millet Groats",
        Amaranth: "Amaranth",
        Semoline: "Semoline",
        Cornmeal: "Cornmeal",
        Bulgur: "Bulgur",
        Avocado: "Avocado",
        Beans: "Beans",
        Eggs: "Eggs",
        Mushrooms: "Mushrooms",
        Cottage_cheese: "Cottage cheese",
        Milk: "Milk",
        Tofu: "Tofu",
        Hummus: "Hummus",
        Plant_milk: "Plant milk",
        Protein_powder: "Protein powder",
        Vegan_protein_powder: "Vegan protein powder",
        Turkey: "Turkey",
        Beef: "Beef",
        Chicken: "Chicken",
        Seafood: "Seafood",
        Pork: "Pork",
        Fish: "Fish",
        Citrus: "Citrus",
        Apple: "Apple",
        Pear: "Pear",
        Kiwi: "Kiwi",
        Bananas: "Bananas",
        Persimmon: "Persimmon",
        Peach: "Peach",
        Berries: "Berries",
        Grape: "Grape",
        Pomegranate: "Pomegranate",
        antibiotics: "Antibiotics < 3 months",
        antibiotics_three: "Antibiotics > 3 months",
        Chemotherapy: "Chemotherapy",
        proton_pump: "Proton pump inhibitors (PPI)",
        laxatives: "Laxatives (laxatives)",
        immunsuppressiva: "Immunosuppressants",
        home: "Home",
        offers: "Offers",
        references: "References",
        about_us: "About us",
        point_one: "Step: Fill out the comprehensive questionnaire truthfully.",
        point_two:
          "Step: Receive the AI-generated analysis report with your individual recommendations.",
        point_three:
          "Step: Implement the simple recommendations that are suitable for everyday use. If you can contact us for further advice and laboratory analyses. feel free to contact us.",
        two_glasses_sec: "up to 0.5 l / 17 oz",
        six_glasses_sec: "0.5-1.5 l / 17-50 oz",
        seven_sec: "1.5-2.5 l / 50-85 oz",
        ten_glasses_sec: "more than 2.5 l / 85 oz",
        how_many_fruits_vegetables: "How many fruits do you eat a day?",
        how_many_vegetables: "How many vegetables do you eat a day?",
        more_than_3: "More than 4",
        how_often_consume_starch:
          "How often per day do you consume starch sources such as potatoes, rice, bread, pasta, cereal flakes, etc.?",
        how_many_servings_wholegrain:
          "How many servings of the starch suppliers consist of wholemeal flour or the whole grain?",
        how_many_portions_nuts_seeds_kernels_per_week:
          "How many portions of nuts, seeds and kernels do you eat per week?",
        how_many_hours_sleep_per_night:
          "How many hours do you sleep on average per night?",
        portion: "1 portion = 1 handful",
        select_work_percentage: "How many hours do you work per week?",
        disease_head: "Have you been diagnosed with one or more diseases?",
        digestive_track: "Digestive tract",
        digestive_Krebsnn: "Cancer",
        airways_Krebsatem: "Cancer",
        skin_newdisease: "Cancer",
        Ulcerative: "Ulcerative colitis/Crohn's disease",
        Diarrhea: "Diarrhea (chronic)",
        Diverticulosis: "Diverticulosis",
        Gastritis: "Gastritis",
        Constipation: "Constipation",
        Flatulence: "Flatulence",
        Airways: "Airways",
        bronchial: "Seasonal bronchial asthma",
        ganzjährig: "Asthma all year round",
        Chronic: "Chronic bronchitis",
        Skin: "Skin, hair, joints, bones",
        Akne: "Acne",
        Eczema: "Eczema",
        Hair_loss: "Hair loss",
        Neurodermatitis: "Neurodermatitis",
        psoriasis: "psoriasis",
        Rheumatoid_arthritis: "Rheumatoid arthritis",
        osteoporosis: "osteoporosis",
        Psyche: "Psyche, nervous system",
        ADHD: "ADHD",
        Anxiety: "Anxiety disorder",
        Autistic: "Autistic spectrum",
        Depression: "Depression",
        Headaches: "Headaches/migraines",
        Alzheimer: "M. Alzheimer's disease",
        Parkinson: "M. Parkinson's disease",
        Fatigue: "Fatigue/exhaustion, chronic",
        Multiple: "Multiple sclerosis",
        Metabolism: "Metabolism, cardiovascular system",
        Diabetes: "Diabetes mellitus, type 1 or 2",
        high_blood_pressure: "High blood pressure",
        low_blood_pressure: "Low blood pressure",
        Overweight: "Overweight",
        Elevated_blood: "Elevated blood",
        Vascular_diseases: "Vascular diseases (e.g. arteriosclerosis)",
        Hypothyroidism: "Hypothyroidism",
        Food_intolerances: "Food intolerances",
        Fructose: "Fructose intolerance",
        Gluten_intolerance: "Gluten intolerance/coeliac disease",
        Lactose_intolerance: "Lactose intolerance",
        IgG_mediated: "IgG-mediated intolerances",
        IgE_mediated: "IgE-mediated allergies",
        Histamine: "Histamine",
        FODMAPs: "FODMAPs",
        Food_allergies: "Food Allergies",
        Peanuts: "Peanuts",
        Celery: "Celery",
        Crustaceans: "Crustaceans such as crabs, shrimps etc.",
        Lupin: "Lupin",
        hazelnuts: "Nuts such as almonds, hazelnuts etc.",
        Sulphur: "Sulphur dioxide/sulphides such as dried fruit",
        mustard: "mustard",
        sesame: "sesame",
        soya: "soya",
        mussels: "Molluscs such as mussels",
        None_of_the_above: "None of the above",
        Alkaline_fasting: "Alkaline fasting",
        Low_Carb: "Low-Carb",
        FODMAP: "FODMAP",
        Intermittent: "Intermittent fasting",
        Mixed_diet: "Mixed diet (omnivore)",
        Wholefood: "Wholefood diet",
        Flexitarians: "Flexitarians",
        vegetarian_options: "Which of these vegetarian diets applies to you?",
        Pescetarisch: "Pescetarian",
        Lakto_Vegetarisch: "Lacto-Vegetarian",
        Ovo_Vegetarisch: "Ovo-Vegetarian",
        Ovo_Lakto_Vegetarisch: "Ovo-Lacto-Vegetarian",
        drink_alcohol: "How often do you drink alcohol per week?",
        Nie: "Never",
        _2_Mal_pro_Woche: "1-2 Glasses",
        _4_Mal_pro_Woche: "2-4 Glasses",
        _6_Mal_pro_Woche: "4-6 Glasses",
        Jeden_Tag: "Every day",
        alcohol_type: "What kind of alcohol do you drink?",
        Wein: "Wine",
        Beer: "Beer",
        Spirits: "Spirits",
        Cocktails: "Cocktails",
        how_much_alcohol: "How much alcohol do you drink per month?",
        _2_servings: "1-2 servings",
        _3_servings: "2-3 servings",
        _4_servings: "3-4 servings",
        More: "More than 4 portions",
        handvoll:
          "1 Portion = 1 Handful e.g. Berries, 2-3 Apricots, 1-2 Mandarins",
        handvoll_veg: "1 Portion = 1 Handful",
        reference_text:
          "1 portion = 1 glass of wine, 3 liters of beer or a glass of schnapps",
        pay_now: "Pay Now and Get Your Plan",
        enter_details: "Enter Your Card Details",
        total: "Total",
        pay_now_btn: "Pay Now",
        guaranteed:
          "Guaranteed to be safe & secure, ensuring that all transactions are protected with the highest level of security.",
        card_number: "Card Number",
        expiration: "Expiration Date",
        coupon: "Coupon Code",
        payment_head: "Your Payment Was Successful – Thank You!",
        payment_para:
          "Thank you for your payment. A detailed report with a comprehensive summary of your results will be sent to your email address",
        payment_btn: "Back to Home",
        please_select: "Please select at least one option!",
        which_oils:
          "Which oils or fats do you need to prepare your meals and how much?",
        cold_dishes:
          "For cold dishes, e.g. salad dressing; for hot dishes, e.g. for sautéing or as a fat spread",
        None: "None",
        Butter: "Butter",
        Margarine: "Margarine",
        coconut_oil: "Other (e.g., virgin coconut oil)",
        Rapeseed_Oil: "Rapeseed Oil",
        Olive_Oil: "Olive Oil",
        Walnut_Oil: "Walnut Oil",
        Flaxseed_Oil: "Flaxseed Oil",
        Hemp_Oil: "Hemp Oil",
        Wheat_Germ: "Wheat Germ Oil",
        Soybean: "Soybean Oil",
        sunflower: "Other (e.g., sunflower oil)",
        Cooking_Butter: "Cooking Butter",
        Coconut_Oil: "Coconut Oil",
        Sunflower_Oil: "Sunflower Oil",
        Peanut_Oil: "Peanut Oil",
        Other: "Other",
        Frying_Oils: "Frying Oils (e.g., HOLL rapeseed or sunflower oil)",
        Streichfett: "spreadable fat",
        kalte: "Cold Kitchen (e.g., Salad Dressings)",
        warme: "Hot Kitchen (e.g., Frying, Steaming)",
        Frittieren: "For Deep Frying",
        processing_payment: "Processing Payment",
        Current_Weight: "Enter Current Weight",
        Target_Weight: "Enter Target Weight",
        Enter_Email: "Enter Your Email",
        invalid_promotion_code:
          "The promotion code you entered is invalid or inactive.",
        payment_error_generic:
          "An error occurred while processing your payment.",
        payment_details_error:
          "Failed to retrieve payment details. Please try again.",
        loading: "Loading...",
        select_options: "Select",
        no_allergies: "No Allergies",
        Blähungen: "Flatulence",
        ergänzen: "Irritable bowel syndrome supplement",
        Rapsl: "HOLL Rapeseed oil",
        Sonnenblumenl: "HO sunflower oil",
        harmonal: "Hormonal Dysfunctions",
        pms: "PMS",
        Menopause: "Menopause",
        zero_portions: "0 portion",
        one_to_two_portions: "1-2 portions",
        three_to_four_portions: "3-4 portions",
        five_or_more_portions: "5 or more portions",
        how_often_eat_meat: "How often do you eat meat per week?",
        Like: "Like",
        Dislike: "Dislike",
        meal_plan_dislike:
          "Let's create a meal plan according to your likes and dislikes:",
        offers: "Offers",
        references: "References",
        all_rights: "Nutriteam. All rights reserved.",
        sea_food: "How often per week do you eat fish or seafood?",
        zero_seafood: "0 Portion",
        one_to_two_seafood: "1 Portion",
        two_or_more_seafood: "2 or more Potrions",
        eggs: "How often do you consume eggs per week?",
        zero_eggs: "0 Portion",
        one_to_two_eggs: "1-2 Portions",
        three_to_four_eggs: "3-4 Portions",
        five_or_more_eggs: "5 or more Portions",
        milk: "How often per day do you consume milk and dairy products?",
        not_at_all: "Not at all",
        one_portion_milk: "1 Portion",
        two_portion_milk: "2 Portions",
        three_portion_milk: "3 Portions",
        three_or_more_milk: "3 or more Portions",
        betri: "Company health management",
        view_details: "View Details",
        services: "Services",
        valid_weights: "Please enter valid weights greater than zero.",
        success_payment: "Payment successful and email sent!",
        select_body_fat: "Please select a body fat level.",
        ki: "AI Health Analysis",
        Essst: "Eating disorder",
        no_disease: "No diseases",
      },
    },

    gm: {
      translation: {
        qualified_results: "Über 430 qualifizierte Ergebnisse",
        Transform_Heading: "Verwandele deine ❤️ Gesundheit mit",
        Personalized: "Personalisierte KI-Gesundheitsanalyse",
        Welcome:
          "Wissenschaftlich basierend, mit jahrelanger Erfahrung und gesundem Menschenverstand ergänzt!",
        Certified:
          "Unser Team aus Ernährungsberater/innen und Bewegungsexperten bietet dir maßgeschneiderte Pläne sowie  Unterstützung auf deinem Weg. Mithilfe unserer innovativen KI-Technologie, die auf die traditionelle mediterrane Ernährung, Hormonbalance, Mikrobiom-Optimierung und Training abgestimmt ist, liefern wir präzise, evidenzbasierte Empfehlungen, die individuell auf dich zugeschnitten sind. Unsere KI-gestützte Anwendung analysiert deine persönlichen Bedürfnisse und entwickelt personalisierte Ernährungs- und Bewegungsstrategien, um deine Gesundheit auf ein neues Level zu heben. Starte noch heute mit deinem KI-Gesundheitscheck!",
        lets_go: "Los geht's",
        choose_individual: "Link zu einer Beispielauswertung",
        How_it_works: "Wie funktioniert es?",
        get_started:
          "Starte einfach und schnell mit unserer 3-Schritt-Anleitung zu deinem persönlichen Analysebericht. Wir machen dir den Einstieg leicht und helfen dir, dich schnell auf unserer Plattform zurechtzufinden und die Ressourcen zu nutzen, die du benötigst, um deine Gesundheitsziele zu erreichen. So funktioniert es:",
        here_work: "So funktioniert's",
        Features: "Unsere Schwerpunkte – deine Vorteile",
        wellness:
          "Willkommen im Feature-Bereich von Nutritionist – Ihrem umfassenden Partner für personalisierte Ernährung und Wellness.",
        personalized: "Ernährungsberatung / Ernährungscoaching",
        personalized_p:
          "Erhalte maßgeschneiderte Ernährungsstrategien, die durch unsere fortschrittliche KI-Technologie optimiert werden. Unsere Anwendung analysiert ausschließlich anonymisierte Daten wie deinen Gesundheitszustand, dein Aktivitätsniveau und deine Ernährungsgewohnheiten und erhebt keine persönlichen Informationen. Lass dir präzise Empfehlungen erstellen und passe deinen Ernährungsplan individuell an. Durch kontinuierliche Updates und intelligente Lernprozesse wird dein Plan regelmäßig optimiert, damit du stets den besten Fortschritt erzielst – ganz im Einklang mit höchsten Datenschutzstandards.",
        guidance: "Trainingsplanung und Coaching für Ausdauersportler",
        guidance_p:
          "Unser Team besteht ausschliesslich aus erfahrenen und studierten Ernährungsberater/innen BSc FH und steht dir während deiner gesamten Reise mit professioneller Beratung und Unterstützung zur Seite. Dabei geht es weit mehr als nur um Ernährungspläne: Wir berücksichtigen deine individuelle Ausgangslage und holen dich dort ab wo du aktuell stehst. Wir werden mit dir zusammen an deinen Gewohnheiten arbeiten, bei Bedarf Strategien gegen emotionales Essen entwickeln und Stolpersteine aus dem Weg räumen.",
        food: "Lebensmittel-Tracking und Rezeptdatenbank mittels App",
        food_p:
          "Verfolge schnell und einfach dein Essen mit unserer benutzerfreundlichen App. Unsere Ernährungsberaterinnen analysieren deine Ernährung und Essgewohnheiten und helfen dir, Verbesserungsmöglichkeiten zu erkennen und umzusetzen. Greife auf eine umfangreiche Sammlung köstlicher und gesunder Rezepte zu, die speziell auf deine Ernährungsbedürfnisse abgestimmt sind. Unsere fortschrittliche KI-Technologie unterstützt dich dabei, indem sie Rezepte und Speisepläne basierend auf deinen Vorlieben und Zielen optimiert.",
        meal: "Essensplanung und Rezepte",
        meal_p:
          "Greife auf eine umfangreiche Sammlung köstlicher und gesunder Rezepte zu, die speziell auf Deine Ernährungsbedürfnisse abgestimmt sind. Unsere fortschrittliche KI-Technologie unterstützt Dich dabei, indem sie Rezepte und Speisepläne basierend auf Deinen Vorlieben und Zielen optimiert. Zusätzlich erstellen unsere Ernährungsberater personalisierte Speisepläne, die durch KI-gesteuerte Empfehlungen ergänzt werden, um Dir zu helfen, den Überblick zu behalten und nahrhafte Mahlzeiten zu genießen. So profitierst Du von einer intelligenten Kombination aus Expertenwissen und modernster Technologie.",
        lifestyle: "Darmcoaching",
        lifestyle_p:
          "Um nachhaltige Ergebnisse zu erzielen, ist mehr als nur ein Diätplan erforderlich. Unsere Ernährungsberater werden mit Dir zusammenarbeiten, um gesunde Gewohnheiten zu entwickeln, emotionales Essen anzugehen und Strategien zur Überwindung von Hindernissen auf dem Weg bereitzustellen.",
        workshops: "Online Food Coaching",
        workshops_p:
          "Erweitere dein Ernährungswissen mit informativen Artikeln und interaktiven Workshops, die durch unsere KI-gestützte App bereitgestellt werden. Die intelligente Plattform liefert dir maßgeschneiderte Lerninhalte und Ressourcen, die auf deinen individuellen Bedürfnissen basieren. Unsere App unterstützt dich dabei, fundierte Entscheidungen zu treffen und bietet dir die Werkzeuge, die du benötigst, um langfristigen Erfolg und gesunde Gewohnheiten umzusetzen.",
        blogs_h: "Unser Blog",
        blogs_p:
          "Unser Blog ist eine Fundgrube informativer und ansprechender Artikel, die von unserem Team verfasst wurden.",
        read_less: "Weniger...",
        read_more: "Mehr...",
        practice_h: "Mehr aus der Praxis",
        practice_p:
          "Lass Dich von den Erfolgsgeschichten unserer Nutzer inspirieren!",
        feedback_p:
          "Viele haben bereits ihre Fortschritte mit unserer App geteilt. Teile auch Du Deine Erfahrungen, um andere zu motivieren und die Weiterentwicklung unserer Technologie zu unterstützen. Dein Feedback trägt dazu bei, unsere App kontinuierlich zu verbessern und wissenschaftliche Erkenntnisse zu erweitern.",
        choose_gender: "Wähle dein Geschlecht ?",
        select_age: "Gebe deine Altersstufe ein",
        select_age_p:
          "Slide to Your Age – Weil jedes Alter seine eigene Weisheit hat",
        next_btn: "Weiter",
        select_goal: "Wähle dein Ziel",
        select_height: "Stelle deine Körpergröße ein",
        select_height_p:
          "Slide to Your Height - Weil jede Körpergröße ihre eigene Balance hat",
        select_body_type: "Wähle dein Körpertyp",
        slim: "Schlank",
        average: "Durchschnittlich",
        heavy: "Schwer",
        lose_weight: "Performance steigern",
        gain_muscle: "Gewichtsmagement",
        get_shredded: "Wohlbefinden steigern",
        specific_goal: "Wähle dein spezifisches Ziel",
        slim_body: "Kraftsport",
        slim_shredded_body: "Ausdauer",
        Mental_Performance: "mentale Leistungsfähigkeit",
        athlete: "Abnehmen",
        hero: "Zunehmen",
        bodybuilder: "Gewicht halten",
        beach_body: "Vitalität steigern",
        workout_body: "Ausgewogen essen",
        cross_fit_body: "Mehr bewegen",
        promote_recovery: "Regeneration unterstützen",
        back: "Zurück",
        stick: "Hälst du dich an eine oder mehrere Ernährungsformen?",
        vegetarian: "Vegetarisch",
        vegan: "Vegan",
        keto: "Keto",
        mediterranean: "Mediterrane Diät",
        none: "Keiner der oben genannten Punkte",
        sugary_food:
          "Wie oft isst oder trinkst du zuckerhaltige Lebensmittel oder Getränke?",
        not_often: "Nicht oft. Ich stehe nicht so auf Süßes.",
        three_times: "3-5 Mal pro Woche",
        every_day: "jeden Tag",
        every_day_4: "Mehrmals täglich",
        water_intake:
          "Wie viel Leitungswasser, Mineralwasser oder ungesüssten Tee trinkst du täglich",
        coffee: "Nur Kaffee oder Tee",
        two_glasses: "Weniger als 2 Gläser",
        six_glasses: "2-6 Gläser",
        seven: "7-10 Gläser",
        ten_glasses: "Mehr als 10 Gläser",
        medications:
          "Welche der folgenden Medikamente nimmst Du derzeit ein oder hast Du in den letzten drei Monaten eingenommen?",
        current_weight:
          "Stelle dein aktuelles Gewicht sowie dein Zielgewicht ein.",
        select_unit: "Einheit auswählen",
        current_weight_lb: "Aktuelles Gewicht",
        target_weight: "Zielgewicht",
        fitness_level: "Wie schätzt du dein aktuelles Leistungslevel ein?",
        fitness_level_p:
          "Wähle auf einer Skala von 1 bis 10, wobei 1 für Anfänger steht und 10 für ein sehr fortgeschrittenes Fitnessniveau. Nutze den Schieberegler, um deinen aktuellen Stand zu bestimmen.",
        fat_level: "Wie hoch ist dein Körperfettanteil",
        meal_plan: "Lass uns einen Essensplan nach deinen Vorlieben erstellen:",
        veggies: "Gemüsesorten",
        grains: "Getreidearten",
        ingredients: "Inhaltsstoffe",
        meat_fish: "Fleisch & Fisch",
        fruits: "Früchte",
        allergic_h: "Wähle aus, gegen was du allergisch bist",
        support_text: "Brauchst du Unterstützung…",
        weight_loss: "bei einer gewünschten Gewichtsabnahme?",
        digestive: "bei Verdauungsproblemen und Unverträglichkeiten?",
        performance:
          "bei der Optimierung deiner physischen und mentalen Leistungsfähigkeit?",
        stabilize: "beim Stabilisieren deines Immun- und Hormonsystems?",
        evaluation:
          "Du bekommst deine Auswertung in 5-10 Minuten. Lese die folgenden Fragen aufmerksam durch und beantworte diese so präzise wie möglich.",
        carefully: "",
        lets_get_started: "Lass uns beginnen",
        Broccoli: "Brokkoli",
        Cauliflower: "Blumenkohl",
        Onion: "Zwiebel",
        Bell_Pepper: "Paprika",
        Eggplant: "Aubergine",
        Cabbage: "Kohl",
        Asparagus: "Spargel",
        Spinach: "Spinat",
        Cucumber: "Gurke",
        Tomato: "Tomate",
        Rice: "Reis",
        Quinoa: "Quinoa",
        Couscous: "Couscous",
        Buckwheat: "Buchweizen",
        Millet_Groats: "Hirse",
        Amaranth: "Amaranth",
        Semoline: "Grieß",
        Cornmeal: "Maismehl",
        Bulgur: "Bulgur",
        Avocado: "Avocado",
        Beans: "Bohnen",
        Eggs: "Eier",
        Mushrooms: "Pilze",
        Cottage_cheese: "Hüttenkäse",
        Milk: "Milch",
        Tofu: "Tofu",
        Hummus: "Hummus",
        Plant_milk: "Pflanzenmilch",
        Protein_powder: "Eiweißpulver",
        Vegan_protein_powder: "Veganer Eiweißpulver",
        Turkey: "Pute",
        Beef: "Rindfleisch",
        Chicken: "Hähnchen",
        Seafood: "Meeresfrüchte",
        Pork: "Schweinefleisch",
        Fish: "Fisch",
        Citrus: "Zitrusfrüchte",
        Apple: "Apfel",
        Pear: "Birne",
        Kiwi: "Kiwi",
        Bananas: "Bananen",
        Persimmon: "Kaki",
        Peach: "Pfirsich",
        Berries: "Beeren",
        Grape: "Traube",
        Pomegranate: "Granatapfel",
        antibiotics: "Antibiotika < 3 Monate",
        antibiotics_three: "Antibiotika > 3 Monate",
        Chemotherapy: "Chemotherapie",
        proton_pump: "Protonenpumpenhemmer (PPI)",
        laxatives: "Laxantien (Abführmittel)",
        immunsuppressiva: "Immunsuppressiva",
        home: "Home",
        offers: "Angebote",
        references: "Referenzen",
        about_us: "Über uns",
        point_one:
          "Schritt: Fülle den umfassenden Fragekatalog wahrheitsgetreu aus.",
        point_two:
          "Schritt: Erhalte den KI-generierten Analysebericht mit deinen individuellen Empfehlungen.",
        point_three:
          "Schritt: Setze die einfachen und alltagstauglichen Empfehlungen um. Bei Bedarf kannst du dich für weiterführende Beratungen und Laboranalysen gerne bei uns melden.",
        two_glasses_sec: "bis zu 0,5 l / 17 oz",
        six_glasses_sec: "0,5-1,5 l / 17-50 oz",
        seven_sec: "1,5-2,5 l / 50-85 oz",
        ten_glasses_sec: "mehr als 2,5 l / 85 oz",
        how_many_fruits_vegetables: "Wie viele Früchte isst du pro Tag?",
        how_many_vegetables: "Wie viel Gemüse isst du pro Tag?",
        more_than_3: "Mehr als 4",
        how_often_consume_starch:
          "Wie häufig pro Tag konsumierst du Stärkelieferanten wie Kartoffeln, Reis, Brot, Pasta, Getreideflocken etc.?",
        how_many_servings_wholegrain:
          "Wie viele Portionen von den Stärkelieferanten bestehen aus Vollkornmehl resp. aus dem ganzen Korn?",
        how_many_portions_nuts_seeds_kernels_per_week:
          "Wie viele Portionen Nüsse, Samen und Kerne isst du pro Woche?",
        how_many_hours_sleep_per_night:
          "Wie viele Stunden schläfst du im Durchschnitt pro Nacht?",
        portion: "1 Portion = 1 Handvoll",
        select_work_percentage: "Wie viele Stunden arbeitest du in der Woche?",
        disease_head:
          " Wurde bei dir eine oder mehrere Krankheiten diagnostiziert resp. hast du Beschwerden?",
        Blähungen: "Blähungen",
        ergänzen: "Reizdarm",
        digestive_track: "Verdauungstrakt",
        digestive_Krebsnn: "Krebs",
        airways_Krebsatem: "Krebs",
        skin_newdisease: "Krebs",
        Ulcerative: "Colitis ulcerosa/Morbus Crohn",
        Diarrhea: "Durchfall (chronisch)",
        Diverticulosis: "Divertikulose",
        Gastritis: "Gastritis",
        Constipation: "Verstopfung",
        Flatulence: "Flatulenz",
        Airways: "Atemwege",
        bronchial: "Asthma bronchiale saisonal",
        ganzjährig: "Asthma ganzjährig",
        Chronic: "Bronchitis chronisch",
        Skin: "Haut, Haare, Gelenke, Knochen",
        Akne: "Akne",
        Eczema: "Ekzeme",
        Hair_loss: "Haarausfall",
        Neurodermatitis: "Neurodermitits",
        psoriasis: "Psoriasis",
        Rheumatoid_arthritis: "Rheumatoide Arthritis",
        osteoporosis: "Osteoporose",
        Psyche: "Psyche, Nervensystem",
        ADHD: "ADHS",
        Anxiety: "Angststörung",
        Autistic: "Autistischer Formenkreis",
        Depression: "Depression",
        Headaches: "Kopfschmerzen/Migräne",
        Alzheimer: "M. Alzheimer",
        Parkinson: "M. Parkinson",
        Fatigue: "Müdigkeit/Erschöpfung, chronisch",
        Multiple: "Multiple Sklerose",
        Metabolism: "Stoffwechsel, Herz-Kreislauf",
        Diabetes: "Diabetes Mellitus, Typ 1 oder 2",
        high_blood_pressure: "Bluthochdruck",
        low_blood_pressure: "Tiefer Blutdruck",
        Overweight: "Übergewicht",
        Elevated_blood: "erhöhte Blutfettwerte (wie z.B. Cholesterin)",
        Vascular_diseases: "Gefässerkrankungen (z.B. Arteriosklerose)",
        Hypothyroidism: "Schilddrüsenunter- oder Überfunktion",
        Food_intolerances: "Nahrungsmittel-Intoleranzen",
        Fructose: "Fruchtzuckerunverträglichkeit",
        Gluten_intolerance: "Glutenintoleranz/Zöliakie",
        Lactose_intolerance: "Milchzuckerunverträglichkeit",
        IgG_mediated: "IgG-vermittelte Intoleranzen",
        IgE_mediated: "IgE-vermittelte Allergien",
        Histamine: "Histamin",
        FODMAPs: "FODMAPs",
        Food_allergies: "Nahrungsmittelallergien",
        Peanuts: "Erdnüsse",
        Celery: "Sellerie",
        Crustaceans: "Krebstiere wie z.B. Krebse, Shrimps etc.",
        Lupin: "Lupine",
        hazelnuts: "Schalenfrüchte wie z.B. Mandeln, Haselnüsse etc.",
        Sulphur: "Schwefeldioxid/Sulfide wie z.B. Trockenobst",
        mustard: "Senf",
        sesame: "Sesam",
        soya: "Soja",
        mussels: "Weichtiere wie z.B. Muscheln",
        None_of_the_above: "Keiner der oben genannten Punkte",
        Alkaline_fasting: "Basenfasten",
        Low_Carb: "Low-Carb",
        FODMAP: "FODMAP",
        Intermittent: "Intervallfasten",
        Mixed_diet: "Mischkost (Alles-Esser)",
        Wholefood: "Vollwerternährung",
        Flexitarians: "Flexitarier",
        vegetarian_options:
          "Welche dieser vegetarischen Ernährungsformen trifft auf dich zu?",
        Pescetarisch: "Pescetarisch (mit Fisch)",
        Lakto_Vegetarisch: "Lakto-Vegetarisch (mit Milchprodukte)",
        Ovo_Vegetarisch: "Ovo-Vegetarisch (mit Eiern)",
        Ovo_Lakto_Vegetarisch:
          "Ovo-Lakto-Vegetarisch (mit Eiern und Milchprodukten)",
        drink_alcohol: "Wie häufig pro Woche trinkst du Alkohol?",
        Nie: "Nie",
        _2_Mal_pro_Woche: "1-2 Gläser",
        _4_Mal_pro_Woche: "2-4 Gläser",
        _6_Mal_pro_Woche: "4-6 Gläser",
        Jeden_Tag: "Jeden Tag",
        alcohol_type: "Welche Art von Alkohol trinkst du?",
        Wein: "Wein",
        Beer: "Bier",
        Spirits: "Spirituosen",
        Cocktails: "Cocktails",
        how_much_alcohol: "Wie viel Alkohol trinkst du pro Monat?",
        _2_servings: "1-2 Standardgläser",
        _3_servings: "2-3 Standardgläser",
        _4_servings: "3-4 Standardgläser",
        More: "Mehr als 4 Standardgläser",
        handvoll:
          "1 Portion = 1 Handvoll z.B. Beeren, 2-3 Aprikosen, 1-2 Mandarinen",
        handvoll_veg: "1 Portion = 1 Handvoll",
        reference_text:
          "1 Standardglas = 3 dl Bier, 1 dl Wein, 1 Aperitif (4 cl) oder 1 Schnaps (3 cl)",
        pay_now:
          "Sichere und schnelle Bezahlung für deine Gesundheitsauswertung.",
        enter_details:
          "Starte sofort mit deiner individuellen Analyse – gib einfach deine Zahlungsdaten ein und leg los! Deine Daten sind bei uns sicher und werden vertraulich behandelt.",
        total: "Insgesamt",
        pay_now_btn: "Jetzt bezahlen",
        guaranteed:
          "Garantiert sicher, so dass alle Transaktionen mit der höchsten Sicherheitsstufe geschützt sind.",
        card_number: "Kartennummer",
        expiration: "Gültigkeitsdatum",
        coupon: "Hast du einen Coupon-Code?",
        payment_head: "Deine Zahlung war erfolgreich – Danke!",
        payment_para:
          "Vielen Dank für deine Zahlung. Ein detaillierter Bericht mit einer umfassenden Zusammenfassung deiner Ergebnisse wird dir per Mail zugesandt an",
        payment_btn: "Zurück zu Startseite",
        please_select: "Bitte wähle mindestens eine Option aus!",
        which_oils:
          "Welche Öle oder Fette brauchst du für die Zubereitung deiner Mahlzeiten?",
        cold_dishes:
          "Für die kalte Küche z.B. Salatsauce; für die warme Küche z.B. zum Anbraten oder als Streichfett",
        None: "Gar nicht",
        Butter: "Butter",
        Margarine: "Margarine",
        coconut_oil: "Anderes z.B. natives Kokosöl ",
        Rapeseed_Oil: "Rapsöl",
        Olive_Oil: "Olivenöl",
        Walnut_Oil: "Baumnussöl",
        Flaxseed_Oil: "Leinöl",
        Hemp_Oil: "Hanföl",
        Wheat_Germ: "Weizenkeimöl",
        Soybean: "Sojaöl",
        sunflower: "Andere wie z.B. Sonnenblumenöl",
        Cooking_Butter: "Bratbutter",
        Coconut_Oil: "Kokosöl",
        Sunflower_Oil: "Sonnenblumenöl",
        Peanut_Oil: "Erdnussöl ",
        Other: "Andere",
        Frying_Oils: "Frittieröle wie z.B. ein HOLL Rapsöl oder Sonnenblumenöl",
        Streichfett: "Streichfett",
        kalte: "Kalte Küche (Salatsauce)",
        warme: "Warme Küche z.B. zum Anbraten, Dünsten",
        Frittieren: "Zum Frittieren",
        processing_payment: "Zahlung wird bearbeitet",
        Current_Weight: "Aktuelles Gewicht eingeben",
        Target_Weight: "Zielgewicht eingeben",
        Enter_Email: "Gib deine E-Mail-Adresse ein.",
        invalid_promotion_code:
          "Der eingegebene Rabattcode ist ungültig oder inaktiv.",
        payment_error_generic:
          "Es ist ein Fehler bei der Zahlungsabwicklung aufgetreten.",
        payment_details_error:
          "Fehler beim Abrufen der Zahlungsdetails. Bitte versuche es erneut.",
        loading: "Laden...",
        select_options: "Auswählen",
        no_allergies: "Keine Allergien",
        Rapsl: "HOLL Rapsöl",
        Sonnenblumenl: "HO-Sonnenblumenöl",
        harmonal: "Hormonelle Dysfunktionen",
        pms: "PMS",
        Menopause: "Menopause",
        zero_portions: "0 Portionen",
        one_to_two_portions: "1-2 Portionen",
        three_to_four_portions: "3-4 Portionen",
        five_or_more_portions: "5 oder mehr Portionen",
        how_often_eat_meat: "Wie häufig pro Woche konsumierst du Fleisch?",
        Like: "Gefällt mir",
        Dislike: "Gefällt mir nicht",
        meal_plan_dislike:
          "Lass uns einen Essensplan nach deinen Vorlieben bzw. Abneigungen erstellen:",
        offers: "Angebote",
        references: "Referenzen",
        all_rights: "Nutriteam. Alle Rechte vorbehalten.",
        sea_food:
          "Wie häufig pro Woche konsumierst du Fisch oder Meeresfrüchte?",
        zero_seafood: "0 Portionen",
        one_to_two_seafood: "1 Portion",
        two_or_more_seafood: "2 oder mehr Potrionen",
        eggs: "Wie häufig pro Woche konsumierst du Eier?",
        zero_eggs: "0 Portion",
        one_to_two_eggs: "1-2 Portionen",
        three_to_four_eggs: "3-4 Portionen",
        five_or_more_eggs: "5 oder mehr Portionen",
        milk: "Wie häufig pro Tag konsumierst du Milch und Milchprodukte?",
        not_at_all: "Gar nicht",
        one_portion_milk: "1 Portion",
        two_portion_milk: "2 Portionen",
        three_portion_milk: "3 Portionen",
        three_or_more_milk: "Mehr als 3 Portionen",
        betri: "Betriebliches Gesundheitsmanagement",
        view_details: "Details anzeigen",
        services: "Dienstleistungen",
        valid_weights: "Bitte geben Sie gültige Gewichte größer als Null ein.",
        success_payment: "Zahlung erfolgreich und E-Mail gesendet!",
        select_body_fat: "Bitte wählen Sie einen Körperfettanteil.",
        ki: "KI-Gesundheitsanalyse",
        Essst: "Essstörung",
        no_disease: "Keine Krankheiten",
      },
    },
    fr: {
      translation: {
        qualified_results: "Plus de 430 résultats qualifiés.",
        Transform_Heading: "Transformez votre santé ❤️ avec nous.",
        Personalized: "Coaching nutritionnel personnalisé.",
        Welcome:
          "Welcome to KI-Nutritem – your expert for personalized nutrition and health optimization.",
        Certified:
          "Nos nutritionnistes certifiés vous offrent des plans personnalisés et un soutien continu tout au long de votre parcours de perte de poids. Grâce à notre modèle LLM avancé aligné sur le régime méditerranéen, l'équilibre hormonal, l'optimisation du microbiome et la condition physique, nous fournissons des recommandations précises et fondées sur des preuves. Notre application alimentée par l'IA analyse vos besoins individuels et crée des stratégies nutritionnelles personnalisées pour amener votre santé à un niveau supérieur. Commencez votre transformation dès aujourd'hui et découvrez la précision et l'efficacité du coaching nutritionnel basé sur les données.",
        lets_go: "C’est parti",
        choose_individual: "Choisissez un programme individuel",
        How_it_works: "Comment ça fonctionne",
        get_started:
          "Commencez facilement et efficacement votre parcours vers une meilleure santé et nutrition grâce à nos instructions étape par étape. Nous facilitons le démarrage et vous aidons à naviguer rapidement sur notre plateforme et à utiliser les ressources nécessaires pour atteindre vos objectifs de santé. Voici comment cela fonctionne :",
        here_work: "Voici comment cela fonctionne",
        Features: "Notre priorité - vos avantages.",
        wellness:
          "Bienvenue dans la section des fonctionnalités de Nutritionist – votre partenaire unique pour la nutrition personnalisée et le bien-être.",
        personalized: "Plans nutritionnels personnalisés.",
        personalized_p:
          "Découvrez des stratégies nutritionnelles sur mesure optimisées par notre technologie avancée d'IA. Notre application analyse uniquement des données anonymisées telles que votre santé, votre niveau d'activité et vos habitudes alimentaires, sans collecter aucune information personnelle. Recevez des recommandations précises et adaptez votre plan nutritionnel de manière individuelle. Grâce à des mises à jour continues et des processus d'apprentissage intelligents, votre plan est régulièrement optimisé afin que vous progressiez toujours de manière optimale - tout en respectant les normes les plus élevées de protection des données.",
        guidance: "Accompagnement par des nutritionnistes certifiés.",
        guidance_p:
          "Notre équipe de nutritionnistes expérimentés et certifiés vous offrira des conseils professionnels et un soutien tout au long de votre parcours. Nous répondrons à vos questions, traiterons vos préoccupations et vous garderons motivé(e) dans la poursuite de vos objectifs.",
        food: "Suivi et analyse de l'alimentation",
        food_p:
          "Suivez facilement votre consommation alimentaire avec notre application simple d’utilisation. Nos nutritionnistes analysent votre régime et vos habitudes alimentaires pour en tirer des enseignements, vous aider à identifier des axes d’amélioration et vous fournir des recommandations personnalisées.",
        meal: "Planification des repas et recettes",
        meal_p:
          "Accédez à une vaste collection de recettes délicieuses et saines, spécialement adaptées à vos besoins alimentaires. Notre technologie avancée d'IA vous aide à optimiser les recettes et les plans de repas en fonction de vos préférences et objectifs. De plus, nos nutritionnistes créent des plans de repas personnalisés, complétés par des recommandations basées sur l'IA, pour vous aider à rester sur la bonne voie et profiter de repas nutritifs. Cela signifie que vous bénéficiez d'une combinaison intelligente de connaissances d'experts et de technologie de pointe.",
        lifestyle: "Coaching en mode de vie et comportements",
        lifestyle_p:
          "Obtenir des résultats durables nécessite plus qu'un simple plan alimentaire. Nos nutritionnistes travailleront avec vous pour développer des habitudes saines, aborder la gestion de l'alimentation émotionnelle et vous fournir des stratégies pour surmonter les obstacles en cours de route.",
        workshops: "Éducation nutritionnelle et ateliers",
        workshops_p:
          "Développez vos connaissances en nutrition grâce à des articles informatifs et des ateliers interactifs proposés via notre application alimentée par IA. La plateforme intelligente vous offre du contenu d'apprentissage et des ressources personnalisées en fonction de vos besoins individuels. Notre application vous aide à prendre des décisions éclairées et vous fournit les outils nécessaires pour réussir sur le long terme et adopter des habitudes saines.",
        blogs_h: "Nos blogs",
        blogs_p:
          "Notre blog est une mine d'informations avec des articles captivants rédigés par notre équipe de nutritionnistes, diététiciens et experts en bien-être. Voici ce à quoi vous pouvez vous attendre de notre blog.",
        read_less: "Lire moins",
        read_more: "Lire la suite",
        practice_h: "Plus de pratiques",
        practice_p:
          "Laissez-vous inspirer par les histoires de succès de nos utilisateurs!",
        feedback_p:
          "De nombreux utilisateurs ont déjà partagé leurs progrès avec notre application. Vous aussi, vous pouvez partager vos expériences pour motiver les autres et soutenir le développement continu de notre technologie. Vos retours nous aident à améliorer constamment notre application et à enrichir les connaissances scientifiques.",
        choose_gender: "Choisissez votre genre?",
        select_goal: "Sélectionnez votre objectif",
        select_age: "Sélectionnez votre tranche d'âge",
        select_age_p:
          "Faites glisser pour votre âge - Car chaque âge a sa propre sagesse",
        next_btn: "Suivant",
        select_height: "Sélectionnez votre taille",
        select_height_p:
          "Faites glisser pour votre taille – Car chaque morphologie a son propre équilibre",
        select_body_type: "Sélectionnez votre type de corps",
        slim: "Mince",
        average: "Moyenne",
        heavy: "Fort",
        lose_weight: "Améliorer la performance",
        gain_muscle: "Gestion du poids",
        get_shredded: "Améliorer le bien-être",
        specific_goal: "Sélectionnez votre objectif spécifique",
        slim_body: "Entraînement de force",
        slim_shredded_body: "Endurance",
        Mental_Performance: "Performance mentale",
        athlete: "Perdre du poids",
        hero: "Gagner du poids",
        bodybuilder: "Maintenir le poids",
        beach_body: "Booster la vitalité",
        workout_body: "Manger des repas équilibrés",
        cross_fit_body: "Augmenter l'activité physique",
        promote_recovery: "Favoriser la récupération",
        stick: "Suivez-vous l'un de ces régimes ?",
        vegetarian: "Végétarien",
        vegan: "Végan",
        keto: "Keto",
        mediterranean: "Méditerranéen",
        none: "Aucun des choix ci-dessus",
        sugary_food:
          "À quelle fréquence mangez-vous ou buvez-vous des aliments ou boissons sucrés ?",
        not_often: "Pas souvent. Je n'aime pas trop les sucreries.",
        three_times: "3-5 fois par semaine",
        every_day: "Presque tous les jours",
        every_day_4: "Plusieurs fois par jour",
        water_intake: "Quelle quantité d'eau buvez-vous chaque jour ?",
        coffee: "Café ou thé uniquement",
        two_glasses: "Moins de 2 verres",
        six_glasses: "2-6 verres",
        seven: "7-10 verres",
        ten_glasses: "Plus de 10 verres",
        medications:
          "Quels médicaments prenez-vous actuellement ou avez-vous pris au cours des trois derniers mois ?",
        current_weight:
          "Quel est votre poids actuel et quel poids souhaitez-vous atteindre ?",
        select_unit: "Sélectionner l'unité",
        current_weight_lb: "Poids actuel",
        target_weight: "Poids cible",
        fitness_level: "Quel est votre niveau de forme physique ?",
        fitness_level_p:
          "Choisissez sur une échelle de 1 à 10, où 1 représente un débutant et 10 un niveau de forme physique très avancé. Utilisez le curseur pour déterminer votre niveau actuel.",
        fat_level: "Choisissez votre niveau de graisse corporelle",
        meal_plan:
          "Laissez-nous créer un plan de repas basé sur vos préférences",
        veggies: "Légumes",
        grains: "Céréales",
        ingredients: "Ingrédients",
        meat_fish: "Viande & Poisson",
        fruits: "Fruits",
        allergic_h: "Sélectionnez ce à quoi vous êtes allergique",
        support_text: "As-tu besoin de soutien?",
        weight_loss:
          "As-tu besoin de soutien pour une perte de poids souhaitée?",
        digestive: "Bei Verdauungsproblemen und Unverträglichkeiten?",
        performance:
          "Pour l'optimisation de ta performance physique et mentale?",
        stabilize: "Pour stabiliser ton système immunitaire et hormonal ?",
        evaluation:
          "Tu recevras ton évaluation dans 5 à 10 minutes. Lis attentivement les questions suivantes et réponds-y de manière aussi précise que possible.",
        carefully:
          "Lisez attentivement les questions suivantes et répondez par oui ou non.",
        lets_get_started: "Commençons",
        Broccoli: "Brocoli",
        Cauliflower: "Chou-fleur",
        Onion: "Oignon",
        Bell_Pepper: "Poivron",
        Eggplant: "Aubergine",
        Cabbage: "Chou",
        Asparagus: "Asperge",
        Spinach: "Épinard",
        Cucumber: "Concombre",
        Tomato: "Tomate",
        Rice: "Riz",
        Quinoa: "Quinoa",
        Couscous: "Couscous",
        Buckwheat: "Sarrasin",
        Millet_Groats: "Graines de millet",
        Amaranth: "Amarante",
        Semoline: "Sémoule",
        Cornmeal: "Farine de maïs",
        Bulgur: "Boulgour",
        Avocado: "Avocat",
        Beans: "Haricots",
        Eggs: "Oeufs",
        Mushrooms: "Champignons",
        Cottage_cheese: "Fromage cottage",
        Milk: "Lait",
        Tofu: "Tofu",
        Hummus: "Houmous",
        Plant_milk: "Lait végétal",
        Protein_powder: "Poudre de protéines",
        Vegan_protein_powder: "Poudre de protéines végétaliennes",
        Turkey: "Dinde",
        Beef: "Boeuf",
        Chicken: "Poulet",
        Seafood: "Fruits de mer",
        Pork: "Porc",
        Fish: "Poisson",
        Citrus: "Agrumes",
        Apple: "Pomme",
        Pear: "Poire",
        Kiwi: "Kiwi",
        Bananas: "Bananes",
        Persimmon: "Kaki",
        Peach: "Pêche",
        Berries: "Baies",
        Grape: "Raisin",
        Pomegranate: "Grenade",
        antibiotics: "Antibiotiques < 3 mois",
        antibiotics_three: "Antibiotiques > 3 mois",
        Chemotherapy: "Chimiothérapie",
        proton_pump: "Inhibiteurs de la pompe à protons (IPP)",
        laxatives: "Laxatifs",
        immunsuppressiva: "Immunosuppresseurs",
        home: "Accueil",
        offers: "Offres",
        references: "Références",
        about_us: "À propos de nous",
        point_one:
          "Étape : Remplissez le questionnaire complet de manière honnête.",
        point_two:
          "Étape : Recevez le rapport d'analyse généré par l'IA avec vos recommandations individuelles.",
        point_three:
          "Étape : Mettez en œuvre les recommandations simples adaptées à un usage quotidien. Si nécessaire, contactez-nous pour des conseils supplémentaires et des analyses en laboratoire.",
        two_glasses_sec: "jusqu'à 0,5 l / 17 oz",
        six_glasses_sec: "0,5-1,5 l / 17-50 oz",
        seven_sec: "1,5-2,5 l / 50-85 oz",
        ten_glasses_sec: "plus de 2,5 l / 85 oz",
        how_many_fruits_vegetables: "Combien de fruits mangez-vous par jour ?",
        how_many_vegetables: "Combien de légumes mangez-vous par jour ?",
        more_than_3: "Plus de 4",
        how_often_consume_starch:
          "À quelle fréquence par jour consommez-vous des sources d'amidon telles que des pommes de terre, du riz, du pain, des pâtes, des flocons de céréales, etc. ?",
        how_many_servings_wholegrain:
          "Combien de portions des sources d'amidon sont à base de farine complète ou de grains entiers ?",
        how_many_portions_nuts_seeds_kernels_per_week:
          "Combien de portions de noix, graines et noyaux mangez-vous par semaine ?",
        how_many_hours_sleep_per_night:
          "Combien d'heures dormez-vous en moyenne par nuit ?",
        portion: "1 portion = 1 poignée",
        select_work_percentage:
          "Combien d'heures travaillez-vous par semaine ?",
        disease_head:
          "Avez-vous été diagnostiqué(e) avec une ou plusieurs maladies ?",
        digestive_track: "Appareil digestif",
        digestive_Krebsnn: "Cancer",
        airways_Krebsatem: "Cancer",
        skin_newdisease: "Cancer",
        Ulcerative: "Colite ulcéreuse/Maladie de Crohn",
        Diarrhea: "Diarrhée (chronique)",
        Diverticulosis: "Diverticulose",
        Gastritis: "Gastrite",
        Constipation: "Constipation",
        Flatulence: "Flatulences",
        Airways: "Voies respiratoires",
        bronchial: "Asthme bronchique saisonnier",
        ganzjährig: "Asthme toute l'année",
        Chronic: "Bronchite chronique",
        Skin: "Peau, cheveux, articulations, os",
        Akne: "Acné",
        Eczema: "Éczema",
        Hair_loss: "Perte de cheveux",
        Neurodermatitis: "Neurodermatite",
        psoriasis: "Psoriasis",
        Rheumatoid_arthritis: "Arthrite rhumatoïde",
        osteoporosis: "Ostéoporose",
        Psyche: "Psyché, système nerveux",
        ADHD: "TDAH",
        Anxiety: "Trouble anxieux",
        Autistic: "Spectre autistique",
        Depression: "Dépression",
        Headaches: "Maux de tête/migraines",
        Alzheimer: "M. Alzheimer",
        Parkinson: "M. Parkinson",
        Fatigue: "Fatigue/extrême épuisement, chronique",
        Multiple: "Sclérose en plaques",
        Metabolism: "Métabolisme, système cardiovasculaire",
        Diabetes: "Diabète sucré, type 1 ou 2",
        high_blood_pressure: "Hypertension artérielle",
        low_blood_pressure: "Hypotension artérielle",
        Overweight: "Surcharge pondérale",
        Elevated_blood: "Taux sanguins élevés",
        Vascular_diseases: "Maladies vasculaires (par exemple, athérosclérose)",
        Hypothyroidism: "Hypothyroïdie",
        Food_intolerances: "Intolérances alimentaires",
        Fructose: "Intolérance au fructose",
        Gluten_intolerance: "Intolérance au gluten/maladie cœliaque",
        Lactose_intolerance: "Intolérance au lactose",
        IgG_mediated: "Intolérances médiées par les IgG",
        IgE_mediated: "Allergies médiées par les IgE",
        Histamine: "Histamine",
        FODMAPs: "FODMAPs",
        Food_allergies: "Allergies alimentaires",
        Peanuts: "Arachides",
        Celery: "Céleri",
        Crustaceans: "Crustacés tels que les crabes, crevettes, etc.",
        Lupin: "Lupin",
        hazelnuts: "Fruits à coque tels que les amandes, noisettes, etc.",
        Sulphur: "Soufre (dioxyde de soufre/sulfites, comme les fruits séchés)",
        mustard: "Moutarde",
        sesame: "Sésame",
        soya: "Soja",
        mussels: "Mollusques tels que les moules",
        None_of_the_above: "Rien de tout cela",
        Alkaline_fasting: "Jeûne alcalin",
        Low_Carb: "Régime pauvre en glucides",
        FODMAP: "Régime FODMAP",
        Intermittent: "Jeûne intermittent",
        Mixed_diet: "Régime mixte (omnivore)",
        Wholefood: "Régime alimentaire à base d'aliments complets",
        Flexitarians: "Flexitariens",
        vegetarian_options:
          "Lequel de ces régimes végétariens vous correspond ?",
        Pescetarisch: "Pescétarien",
        Lakto_Vegetarisch: "Lacto-Végétarien",
        Ovo_Vegetarisch: "Ovo-Végétarien",
        Ovo_Lakto_Vegetarisch: "Ovo-Lacto-Végétarien",
        drink_alcohol:
          "À quelle fréquence buvez-vous de l'alcool par semaine ?",
        Nie: "Jamais",
        _2_Mal_pro_Woche: "1-2 verres",
        _4_Mal_pro_Woche: "2-4 verres",
        _6_Mal_pro_Woche: "4-6 verres",
        Jeden_Tag: "Tous les jours",
        alcohol_type: "Quel type d'alcool buvez-vous ?",
        Wein: "Vin",
        Beer: "Bière",
        Spirits: "Spiritueux",
        Cocktails: "Cocktails",
        how_much_alcohol: "Combien d'alcool buvez-vous par mois ?",
        _2_servings: "1-2 portions",
        _3_servings: "2-3 portions",
        _4_servings: "3-4 portions",
        More: "Plus de 4 portions",
        handvoll:
          "1 Portion = 1 poignée, par exemple des baies, 2-3 abricots, 1-2 mandarines",
        handvoll_veg: "1 Portion = 1 poignée",
        reference_text:
          "1 portion = 1 verre de vin, 3 litres de bière ou un verre de schnaps",
        pay_now: "Payer maintenant et obtenir votre plan",
        enter_details: "Entrez les détails de votre carte",
        total: "Total",
        pay_now_btn: "Payer maintenant",
        guaranteed:
          "Garanti sûr et sécurisé, assurant que toutes les transactions sont protégées avec le plus haut niveau de sécurité.",
        card_number: "Numéro de carte",
        expiration: "Date d'expiration",
        coupon: "Code de coupon",
        payment_head: "Votre paiement a été effectué avec succès – Merci !",
        payment_btn: "Retour à l'accueil",
        which_oils:
          "Quels huiles ou graisses utilisez-vous pour préparer vos repas et en quelle quantité ?",
        cold_dishes:
          "Pour les plats froids, par exemple pour les vinaigrettes ; pour les plats chauds, par exemple pour la cuisson ou comme tartinade",
        None: "Aucune",
        Butter: "Beurre",
        Margarine: "Margarine",
        coconut_oil: "Autre (par exemple, huile de coco vierge)",
        Rapeseed_Oil: "Huile de colza",
        Olive_Oil: "Huile d'olive",
        Walnut_Oil: "Huile de noix",
        Flaxseed_Oil: "Huile de lin",
        Hemp_Oil: "Huile de chanvre",
        Wheat_Germ: "Huile de germe de blé",
        Soybean: "Huile de soja",
        sunflower: "Autre (par exemple, huile de tournesol)",
        Cooking_Butter: "Beurre de cuisson",
        Coconut_Oil: "Huile de coco",
        Sunflower_Oil: "Huile de tournesol",
        Peanut_Oil: "Huile de cacahuète",
        Other: "Autre",
        Frying_Oils:
          "Huiles pour la friture (par exemple, huile de colza ou de tournesol HOLL)",
        Streichfett: "Graisse à tartiner",
        kalte: "Cuisine froide (par exemple, vinaigrettes)",
        warme: "Cuisine chaude (par exemple, friture, cuisson à la vapeur)",
        Frittieren: "Pour la friture",
        processing_payment: "Traitement du paiement",
        Current_Weight: "Entrez le poids actuel",
        Target_Weight: "Entrez le poids cible",
        Blähungen: "Ballonnements",
        ergänzen: "Complément pour le syndrome de l'intestin irritable",
        Rapsl: "Huile de colza HOLL",
        Sonnenblumenl: "Huile de tournesol HO",
        harmonal: "Dysfonctionnements hormonaux",
        pms: "Syndrome prémenstruel",
        Menopause: "Ménopause",
        zero_portions: "0 portion",
        one_to_two_portions: "1-2 portions",
        three_to_four_portions: "3-4 portions",
        five_or_more_portions: "5 ou plus de portions",
        how_often_eat_meat:
          "À quelle fréquence mangez-vous de la viande par semaine ?",
        offers: "Offres",
        references: "Références",
        sea_food:
          "À quelle fréquence mangez-vous du poisson ou des fruits de mer par semaine ?",
        zero_seafood: "0 portion",
        one_to_two_seafood: "1 portion",
        two_or_more_seafood: "2 ou plus de portions",
        eggs: "À quelle fréquence consommez-vous des œufs par semaine ?",
        zero_eggs: "0 portion",
        one_to_two_eggs: "1-2 portions",
        three_to_four_eggs: "3-4 portions",
        five_or_more_eggs: "5 ou plus de portions",
        milk: "À quelle fréquence consommez-vous du lait et des produits laitiers par jour ?",
        not_at_all: "Pas du tout",
        one_portion_milk: "1 portion",
        two_portion_milk: "2 portions",
        three_portion_milk: "3 portions",
        three_or_more_milk: "3 portions ou plus",
        betri: "Gestion de la santé en entreprise",
        view_details: "Voir les détails",
        services: "Services",
        valid_weights: "Veuillez entrer un poids valide supérieur à zéro.",
        success_payment: "Paiement réussi et e-mail envoyé !",
        select_body_fat:
          "Veuillez sélectionner un niveau de graisse corporelle.",
        ki: "Analyse de santé IA",
        Essst: "Trouble alimentaire",
        Address: "Adresse",
        Enter_Email: "Entrez votre e-mail.",
        back: "Retour",
        invalid_promotion_code:
          "Le code promotionnel que vous avez saisi est invalide ou inactif.",
        payment_error_generic:
          "Une erreur est survenue lors du traitement de votre paiement.",
        payment_details_error:
          "Échec de la récupération des détails de paiement. Veuillez réessayer.",
        loading: "Chargement...",
        select_options: "Sélectionner",
        no_allergies: "Pas d'allergies",
        Like: "J'aime",
        Dislike: "Je n'aime pas",
        meal_plan_dislike:
          "Établissons un plan alimentaire en fonction de ce que tu aimes ou n'aimes pas :",
        all_rights: " Nutriteam. Tous droits réservés.",
        please_select: "Choisis au moins une option !",
        no_disease: "Aucune maladie",
        payment_para:
          "Merci beaucoup pour ton paiement. Un rapport détaillé avec un résumé complet de tes résultats sera envoyé à ton adresse e-mail",
      },
    },
  },
  keySeparator: false,
  interpolation: { escapeValue: false },
});

export default i18n;
