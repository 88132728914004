import React, { useState, useEffect } from "react";
import { Slider } from "antd";
import Arrow from "../../assets/images/arrow.svg";
import { useTranslation } from "react-i18next";

const VegetableIntakeSelection = ({ onVegetableIntakeSelect, onNextSlide }) => {
  const [intake, setIntake] = useState(0);
  const { t } = useTranslation();
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData && userData["How many vegetables do you eat a day?"]) {
      setIntake(userData["How many vegetables do you eat a day?"]);
    }
  }, []);
  const handleIntakeChange = (value) => {
    setIntake(value);
    onVegetableIntakeSelect(value);
  };

  const formatter = (value) => {
    const labels = ["0", "1", "2", "3", t("more_than_3")];
    return labels[value];
  };

  return (
    <div className="fitness-level-selection">
      <h3>{t("how_many_vegetables")}</h3>
      <p>{t("handvoll_veg")}</p>
      <div className="slider-container">
        <Slider
          min={0}
          max={4}
          step={0.5}
          value={intake}
          onChange={handleIntakeChange}
          tipFormatter={formatter}
          marks={{
            0: "0",
            0.5: "0.5",
            1: "1",
            1.5: "1.5",
            2: "2",
            2.5: "2.5",
            3: "3",
            3.5: "3.5",
            4: t("more_than_3"),
          }}
        />
      </div>
      <button
        className="start-button btn btn-default"
        onClick={onNextSlide}
        // disabled={intake === 0}
      >
        {t("next_btn")}
        <img className="img-fluid" src={Arrow} alt="arrow" />
      </button>
    </div>
  );
};

export default VegetableIntakeSelection;
