import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const AlcoholConsumptionFrequency = ({
  onAlcoholFrequencySelect,
  onNextSlide,
}) => {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState("");
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData && userData["How much alcohol do you drink?"]) {
      setSelectedOption(userData["How much alcohol do you drink?"]);
    }
  }, []);
  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    onAlcoholFrequencySelect(option);
    onNextSlide();
  };

  return (
    <div className="sugary-foods-selection">
      <h3>{t("how_much_alcohol")}</h3>
      <p>{t("reference_text")}</p>
      <div className="sugary-foods-buttons diet-options">
        {[
          { text: t("_2_servings") },
          { text: t("_3_servings") },
          { text: t("_4_servings") },
          { text: t("More") },
        ].map(({ text }) => (
          <button
            key={text}
            onClick={() => handleOptionSelect(text)}
            className={
              selectedOption === text
                ? "selected btn btn-default"
                : "btn btn-default"
            }
          >
            {t(text)}
          </button>
        ))}
      </div>
    </div>
  );
};

export default AlcoholConsumptionFrequency;
