// import React, { useEffect, useState } from "react";
// import "../../assets/sass/_nutricheck.scss";
// import Slim from "../../assets/images/BEACH_BODY.png";
// import Average from "../../assets/images/WORKOUT_BODY 1.png";
// import Heavy from "../../assets/images/CROSS_FIT_BODY 1.png";
// import Female from "../../assets/images/female.png";
// import { useTranslation } from "react-i18next";

// const AdditionalGetShreddedOptions = ({
//   onAdditionalGoalOptionSelect,
//   onNextSlide,
// }) => {
//   const { t } = useTranslation();
//   const [selectedShreddedGoal, setSelectedShreddedGoal] = useState(null);

//   const selectedGender = localStorage.getItem("gender");
//   const images =
//     selectedGender === "female"
//       ? {
//           slim: Female,
//           average: Female,
//           heavy: Female,
//         }
//       : {
//           slim: Slim,
//           average: Average,
//           heavy: Heavy,
//         };

//   useEffect(() => {
//     // Retrieve userData from localStorage and set the selected shredded goal
//     const userData = JSON.parse(localStorage.getItem("userData"));
//     if (userData && userData["Select Your Specific Goal?"]) {
//       setSelectedShreddedGoal(userData["Select Your Specific Goal?"]);
//     }
//   }, []);

//   const handleShreddedGoalSelection = (goal) => {
//     setSelectedShreddedGoal(goal); // Update the local state
//     onAdditionalGoalOptionSelect(goal); // Trigger the parent callback
//     onNextSlide(); // Proceed to the next slide
//   };

//   return (
//     <div className="additional-goal-options goal-selection">
//       <h3>{t("specific_goal")}</h3>
//       <div className="goal-options">
//         <button
//           className={`btn btn-default ${
//             selectedShreddedGoal === "Beach Body" ? "selected" : ""
//           }`}
//           onClick={() => handleShreddedGoalSelection("Beach Body")}
//         >
//           <img src={images.slim} alt="beach-body-img" />
//           <div>{t("beach_body")}</div>
//         </button>
//         <button
//           className={`btn btn-default ${
//             selectedShreddedGoal === "Workout Body" ? "selected" : ""
//           }`}
//           onClick={() => handleShreddedGoalSelection("Workout Body")}
//         >
//           <img src={images.average} alt="workout-body-img" />
//           <div>{t("workout_body")}</div>
//         </button>
//         <button
//           className={`btn btn-default ${
//             selectedShreddedGoal === "Cross-fit Body" ? "selected" : ""
//           }`}
//           onClick={() => handleShreddedGoalSelection("Cross-fit Body")}
//         >
//           <img src={images.heavy} alt="cross-fit-body-img" />
//           <div>{t("cross_fit_body")}</div>
//         </button>
//         <button
//           className={`btn btn-default ${
//             selectedShreddedGoal === "Promote Recovery" ? "selected" : ""
//           }`}
//           onClick={() => handleShreddedGoalSelection("Promote Recovery")}
//         >
//           <img src={images.heavy} alt="promote-recovery-img" />
//           <div>{t("promote_recovery")}</div>
//         </button>
//       </div>
//     </div>
//   );
// };

// export default AdditionalGetShreddedOptions;

import React, { useEffect, useState } from "react";
import "../../assets/sass/_nutricheck.scss";
import Slim from "../../assets/images/BEACH_BODY.png";
import Average from "../../assets/images/WORKOUT_BODY 1.png";
import Heavy from "../../assets/images/CROSS_FIT_BODY 1.png";
import Female from "../../assets/images/boost-performance.png";
import Arrow from "../../assets/images/arrow.svg";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const AdditionalGetShreddedOptions = ({
  onAdditionalGoalOptionSelect,
  onNextSlide,
}) => {
  const { t } = useTranslation();
  const [selectedShreddedGoals, setSelectedShreddedGoals] = useState([]);

  const selectedGender = localStorage.getItem("gender");
  const images =
    selectedGender === "female"
      ? { slim: Female, average: Female, heavy: Female }
      : { slim: Slim, average: Average, heavy: Heavy };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData")) || {};
    const selectedShreddedGoals =
      userData["Select Your Specific Goal?"]?.["Enhance Well-being"] || [];

    // Ensure it's always an array
    setSelectedShreddedGoals(
      Array.isArray(selectedShreddedGoals) ? selectedShreddedGoals : []
    );
  }, []);

  const handleShreddedGoalSelection = (goal) => {
    setSelectedShreddedGoals((prevSelected) => {
      const updatedSelections = prevSelected.includes(goal)
        ? prevSelected.filter((g) => g !== goal)
        : [...prevSelected, goal];

      return updatedSelections;
    });
  };

  const handleNextClick = () => {
    if (selectedShreddedGoals.length === 0) {
      Swal.fire({
        icon: "warning",
        title: t("please_select"),
        confirmButtonText: "OK",
        background: "#fff",
      });
      return;
    }

    const userData = JSON.parse(localStorage.getItem("userData")) || {};

    userData["Select Your Specific Goal?"] = {
      ...userData["Select Your Specific Goal?"],
      "Enhance Well-being": selectedShreddedGoals.flat(),
    };

    localStorage.setItem("userData", JSON.stringify(userData));

    onAdditionalGoalOptionSelect(selectedShreddedGoals);
    onNextSlide();
  };

  return (
    <div className="multiple-issues-selection">
      <h3>{t("specific_goal")}</h3>
      <div className="issues-options">
        <div className="goal-options">
          <button
            className={`btn btn-default ${
              selectedShreddedGoals.includes("Boost Vitality") ? "selected" : ""
            }`}
            onClick={() => handleShreddedGoalSelection("Boost Vitality")}
          >
            <img src={images.slim} alt="beach-body-img" />
            <div>{t("beach_body")}</div>
          </button>
          <button
            className={`btn btn-default ${
              selectedShreddedGoals.includes("Eat Balanced Meals")
                ? "selected"
                : ""
            }`}
            onClick={() => handleShreddedGoalSelection("Eat Balanced Meals")}
          >
            <img src={images.average} alt="workout-body-img" />
            <div>{t("workout_body")}</div>
          </button>
          <button
            className={`btn btn-default ${
              selectedShreddedGoals.includes("Increase Physical Activity")
                ? "selected"
                : ""
            }`}
            onClick={() =>
              handleShreddedGoalSelection("Increase Physical Activity")
            }
          >
            <img src={images.heavy} alt="cross-fit-body-img" />
            <div>{t("cross_fit_body")}</div>
          </button>
          <button
            className={`btn btn-default ${
              selectedShreddedGoals.includes("Promote Recovery")
                ? "selected"
                : ""
            }`}
            onClick={() => handleShreddedGoalSelection("Promote Recovery")}
          >
            <img src={images.heavy} alt="promote-recovery-img" />
            <div>{t("promote_recovery")}</div>
          </button>
        </div>
      </div>
      <button
        onClick={handleNextClick}
        className="btn btn-default start-button"
      >
        {t("next_btn")}
        <img className="img-fluid" src={Arrow} alt="arrow" />
      </button>
    </div>
  );
};

export default AdditionalGetShreddedOptions;
