import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Arrow from "../../assets/images/arrow.svg";
import Swal from "sweetalert2";

const Diseases = ({ onDiseaseSelect, onNextSlide }) => {
  // Use an object to store selected preferences for each category
  const [gender, setGender] = useState(null);
  const [selectedPreferences, setSelectedPreferences] = useState({
    digestive: [],
    airways: [],
    skin: [],
    psyche: [],
    metabolism: [],
    food: [],
    foodAllergies: [],
    hormonal: [],
    none: [], // Treat 'none' as an array
  });

  const { t } = useTranslation();
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (
      userData &&
      userData[
        "Have you been diagnosed with one or more illnesses or are there illnesses in your family (parents/grandparents/siblings)?"
      ]
    ) {
      setSelectedPreferences(
        userData[
          "Have you been diagnosed with one or more illnesses or are there illnesses in your family (parents/grandparents/siblings)?"
        ]
      );
    }
  }, []);
  useEffect(() => {
    const storedGender = localStorage.getItem("gender");
    setGender(storedGender);
  }, []);

  const handlePreferenceSelect = (category, preference) => {
    setSelectedPreferences((prev) => {
      let updatedCategory;

      // If 'none' is selected, deselect all other categories and select "none"
      if (category === "none") {
        updatedCategory = prev.none.length === 0 ? ["none"] : []; // Toggle "None of the Above"
        // If 'none' is selected, clear all other preferences
        return Object.keys(prev).reduce((acc, key) => {
          acc[key] = key === "none" ? updatedCategory : [];
          return acc;
        }, {});
      }

      const currentCategory = prev[category];
      // If the preference is already selected, remove it, otherwise add it
      updatedCategory = currentCategory.includes(preference)
        ? currentCategory.filter((item) => item !== preference)
        : [...currentCategory, preference];

      // If any preference is selected in other categories, deselect "None of the Above"
      if (updatedCategory.length > 0 && prev.none.length > 0) {
        return {
          ...prev,
          [category]: updatedCategory,
          none: [],
        };
      }

      return {
        ...prev,
        [category]: updatedCategory,
      };
    });
  };

  const handleNextClick = () => {
    // Ensure at least one preference is selected in any category, except "none"
    const anySelected = Object.values(selectedPreferences).some(
      (category, key) => key !== "none" && category.length > 0
    );

    if (anySelected || selectedPreferences.none.length > 0) {
      onDiseaseSelect(selectedPreferences);
      onNextSlide();
    } else {
      // alert(t("please_select"));
      Swal.fire({
        icon: "warning",
        title: t("please_select"),
        confirmButtonText: "OK",
        background: "#fff",
      });
    }
  };

  const digestive = [
    t("digestive_Krebsnn"),
    t("Ulcerative"),
    t("Diarrhea"),
    t("Diverticulosis"),
    t("Gastritis"),
    t("Constipation"),
    t("Flatulence"),
    t("Blähungen"),
    t("ergänzen"),
  ].sort();

  const airways = [
    t("bronchial"),
    t("ganzjährig"),
    t("Chronic"),
    t("airways_Krebsatem"),
  ].sort();

  const skin = [
    t("Akne"),
    t("Eczema"),
    t("Hair_loss"),
    t("Neurodermatitis"),
    t("psoriasis"),
    t("Rheumatoid_arthritis"),
    t("osteoporosis"),
    t("skin_newdisease"),
  ].sort();

  const psyche = [
    t("ADHD"),
    t("Anxiety"),
    t("Autistic"),
    t("Depression"),
    t("Headaches"),
    t("Alzheimer"),
    t("Parkinson"),
    t("Fatigue"),
    t("Multiple"),
    t("Essst"),
  ].sort();

  const metabolism = [
    t("Diabetes"),
    t("high_blood_pressure"),
    t("low_blood_pressure"),
    t("Overweight"),
    t("Elevated_blood"),
    t("Vascular_diseases"),
    t("Hypothyroidism"),
  ].sort();

  const food = [
    t("Fructose"),
    t("Gluten_intolerance"),
    t("Lactose_intolerance"),
    t("IgG_mediated"),
    t("IgE_mediated"),
    t("Histamine"),
    t("FODMAPs"),
  ].sort();

  const food_allergies = [
    t("Eggs"),
    t("Milk"),
    t("Peanuts"),
    t("Fish"),
    t("Celery"),
    t("Crustaceans"),
    t("Lupin"),
    t("hazelnuts"),
    t("Sulphur"),
    t("mustard"),
    t("sesame"),
    t("soya"),
    t("mussels"),
  ].sort();

  const harmonal_allergies = [t("pms"), t("Menopause")];

  return (
    <div className="meal-plan-preferences">
      <h3>{t("disease_head")}</h3>

      {/* Digestive */}
      <h4>{t("digestive_track")}</h4>
      <div className="preference-options">
        {digestive.map((preference) => (
          <button
            key={preference}
            onClick={() => handlePreferenceSelect("digestive", preference)}
            className={
              selectedPreferences.digestive.includes(preference)
                ? "selected btn btn-default"
                : "btn btn-default"
            }
          >
            {preference}
          </button>
        ))}
      </div>

      {/* Airways */}
      <h4>{t("Airways")}</h4>
      <div className="preference-options">
        {airways.map((preference) => (
          <button
            key={preference}
            onClick={() => handlePreferenceSelect("airways", preference)}
            className={
              selectedPreferences.airways.includes(preference)
                ? "selected btn btn-default"
                : "btn btn-default"
            }
          >
            {preference}
          </button>
        ))}
      </div>

      {/* Skin */}
      <h4>{t("Skin")}</h4>
      <div className="preference-options">
        {skin.map((preference) => (
          <button
            key={preference}
            onClick={() => handlePreferenceSelect("skin", preference)}
            className={
              selectedPreferences.skin.includes(preference)
                ? "selected btn btn-default"
                : "btn btn-default"
            }
          >
            {preference}
          </button>
        ))}
      </div>

      {/* Psyche */}
      <h4>{t("Psyche")}</h4>
      <div className="preference-options">
        {psyche.map((preference) => (
          <button
            key={preference}
            onClick={() => handlePreferenceSelect("psyche", preference)}
            className={
              selectedPreferences.psyche.includes(preference)
                ? "selected btn btn-default"
                : "btn btn-default"
            }
          >
            {preference}
          </button>
        ))}
      </div>

      {/* Metabolism */}
      <h4>{t("Metabolism")}</h4>
      <div className="preference-options">
        {metabolism.map((preference) => (
          <button
            key={preference}
            onClick={() => handlePreferenceSelect("metabolism", preference)}
            className={
              selectedPreferences.metabolism.includes(preference)
                ? "selected btn btn-default"
                : "btn btn-default"
            }
          >
            {preference}
          </button>
        ))}
      </div>

      {/* Food Intolerances */}
      <h4>{t("Food_intolerances")}</h4>
      <div className="preference-options">
        {food.map((preference) => (
          <button
            key={preference}
            onClick={() => handlePreferenceSelect("food", preference)}
            className={
              selectedPreferences.food.includes(preference)
                ? "selected btn btn-default"
                : "btn btn-default"
            }
          >
            {preference}
          </button>
        ))}
      </div>

      {/* Food Allergies */}
      <h4>{t("Food_allergies")}</h4>
      <div className="preference-options">
        {food_allergies.map((preference) => (
          <button
            key={preference}
            onClick={() => handlePreferenceSelect("foodAllergies", preference)}
            className={
              selectedPreferences.foodAllergies.includes(preference)
                ? "selected btn btn-default"
                : "btn btn-default"
            }
          >
            {preference}
          </button>
        ))}
      </div>

      {/* Hormonal Allergies */}

      {gender === "female" && (
        <>
          {" "}
          <h4>{t("harmonal")}</h4>
          <div className="preference-options">
            {harmonal_allergies.map((preference) => (
              <button
                key={preference}
                onClick={() => handlePreferenceSelect("hormonal", preference)}
                className={
                  selectedPreferences.hormonal.includes(preference)
                    ? "selected btn btn-default"
                    : "btn btn-default"
                }
              >
                {preference}
              </button>
            ))}
          </div>
        </>
      )}

      {/* None of the Above */}
      <h4>{t("None_of_the_above")}</h4>
      <div className="preference-options">
        <button
          onClick={() => handlePreferenceSelect("none", "none")}
          className={
            selectedPreferences.none.length > 0
              ? "selected btn btn-default"
              : "btn btn-default"
          }
        >
          {t("None_of_the_above")}
        </button>
      </div>

      {/* Next Button */}
      <button
        onClick={handleNextClick}
        className="btn btn-default start-button"
      >
        {t("next_btn")}
        <img className="img-fluid" src={Arrow} alt="arrow" />
      </button>
    </div>
  );
};

export default Diseases;
